import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Group from "./Group";
import Divider from "antd/lib/divider";
function Groups(props) {
  const [loadMore, setLoadMore] = useState(false);
  return (
    <React.Fragment>
      {props.groups.map((obj, idx) => {
        if (
          obj.groupid &&
          idx + 1 < props.groups.length &&
          obj.seniors &&
          !props.groups[idx + 1].seniors
        ) {
          return loadMore || idx < 15 ? (
            <React.Fragment key={`grp-li-${obj?.groupid ?? obj?.virtualuid}`}>
              <Group
                groupName={obj.name}
                members={obj.members}
                groupid={obj.groupid}
                virtualuid={obj.virtualuid}
                plug={props.plugOn}
                istemp={obj.istemp}
                conversation={obj.conversation}
                seniors={obj.seniors || {}}
                myUid={props.myUid}
              />
              <Divider style={{ lineHeight: "2", fontWeight: "bolder" }}>
                Add Conversation
              </Divider>
            </React.Fragment>
          ) : null;
        } else
          return loadMore || idx < 15 ? (
            <Group
              key={`grp-li-${obj?.groupid ?? obj?.virtualuid}`}
              groupName={obj.name}
              members={obj.members}
              groupid={obj.groupid}
              virtualuid={obj.virtualuid}
              plug={props.plugOn}
              istemp={obj.istemp}
              conversation={obj.conversation}
              seniors={obj.seniors || {}}
              myUid={props.myUid}
            />
          ) : null;
      })}
      {!loadMore ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <button
            className="group_load_btn"
            onClick={() => {
              setLoadMore(true);
            }}
          >
            Load More
          </button>
        </div>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return { groups: state.groups, plugOn: state.plugON, myUid: state.user.uid };
};
export default connect(mapStateToProps, null)(React.memo(Groups));

export default class LocalStorageClient {
  constructor() {
    this.lastRecordingTimestamp = {};
    this.lastMsgInGroup = {};
  }

  //   init() {

  //   }

  getLastMsgInGroupTimestamp() {
    let lastMsgInGroup = localStorage.getItem("plugApp_last_msg_timestamp");
    if (lastMsgInGroup) {
      this.lastMsgInGroup = JSON.parse(lastMsgInGroup);
    }
    return this.lastMsgInGroup;
  }

  setLastMsgInGroupTimeStamp(payload) {
    localStorage.setItem("plugApp_last_msg_timestamp", JSON.stringify(payload));
  }

  getLastVisitDay() {
    const lastVisitDay = localStorage.getItem("plugApp_last_visit_timestamp");
    return JSON.parse(lastVisitDay);
  }
  setLastVisitDay() {
    localStorage.setItem("plugApp_last_visit_timestamp", new Date().getTime());
  }
  getLastReadRecording() {
    let lastRecording = localStorage.getItem("plugApp_last_recording");
    if (lastRecording) {
      this.lastRecordingTimestamp = JSON.parse(lastRecording);
    }
    return this.lastRecordingTimestamp;
  }
  setLastReadRecording(temp_last_Recording) {
    localStorage.setItem(
      "plugApp_last_recording",
      JSON.stringify(temp_last_Recording)
    );
    // console.log(temp_last_Recording);
  }
}

import React, { useEffect, useState } from "react";
import "../CreateGroup/CreateGroup.css";
import "./GroupSetting.css";
import { connect } from "react-redux";
import { showGroupSettings } from "../../../Redux/Actions/Action";
import { activateGroup } from "../../../Redux/Actions/Action";
import { CrossLogo, CancelLogo, Dots } from "../../../assets/index.js";
import { Avatar, message } from "antd";
import { delay, hash } from "../../../logic/common/Utils";
import { updateGroup, deleteGroup } from "../../../Redux/Actions/WsActions";
// random dummy list of user who use this image;
const regexEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
const colorList = [
  "#f56a90",
  "#7265e6",
  "#ffbf9f",
  "#00a2ae",
  "#f1a200",
  "#00a2ae",
  "#7265e6",
  "#f56a90",
  "#ffbf9f",

  "#ffbf9f",
  "#f1a200",
];

function GroupSetting(props) {
  // console.log(props);
  const [userEmail, setEmail] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [userList, setUser] = useState(props.userEmails);
  const [userEmails, setUserEmails] = useState(props.userEmails);
  const [groupName, setGroupName] = useState(props.activeGroup.groupName);

  useEffect(() => {
    let selectedUser = [
      ...props.activeGroup.members.map((obj) => {
        return { email: obj?.email ?? obj?.displayName, uid: obj.uid };
      }),
    ];
    let user = props.userEmails.filter(
      (user_obj) => !selectedUser.some((e) => e.uid === user_obj.uid)
    );
    setSelectedList(selectedUser);

    setUserEmails(user);
    setUser(user);
  }, []);
  const handleSearchUser = (value) => {
    setEmail(value);
    let newUser = [];
    if (value === "" || value === null) {
      setUser(userEmails);
    } else {
      newUser = userEmails.filter((user) => {
        return user.email.toLowerCase().includes(value.toLowerCase());
      });

      if (newUser.length === 0 || newUser.length === 1) {
        if (value.match(regexEmail)) {
          newUser.push({
            email: value,
          });
        }
      }

      setUser(newUser);
    }
  };

  const handleAddUser = (e) => {
    if (selectedList.some((user_obj) => e.uid === user_obj.uid)) {
      message.error("already added");

      return;
    }
    setSelectedList((prev) => [{ email: e.email, uid: e.uid }, ...prev]);
    setUser((prev) => {
      prev = prev.filter((user_obj) => user_obj.uid !== e.uid);
      return prev;
    });
    setUserEmails((prev) => {
      prev = prev.filter((user_obj) => user_obj.uid !== e.uid);
      return prev;
    });
  };

  const handleUpdateGroup = async () => {
    let payload = {};
    payload.name = groupName;
    payload.groupid = props.activeGroup.groupid;
    payload.members = [];
    selectedList.forEach((obj) => {
      let tempObj = {};
      tempObj.email = obj.email;
      tempObj.uid = obj.uid;
      tempObj.name = obj.email.split("@")[0];
      payload.members.push(tempObj);
    });

    // console.log(payload);
    await props.updateGroup(payload);
    setUser([]);
    setEmail("");
    setSelectedList([]);
    props.closeGroupSettingsAction();
  };

  const removeUserEmail = (e) => {
    setSelectedList((prev) => {
      prev = prev.filter((user_obj) => user_obj.uid !== e.uid);
      return prev;
    });
    setUserEmails((prev) => [{ email: e.email, uid: e.uid }, ...prev]);
  };

  const handleDeleteGroup = async () => {
    let payload = {};

    payload.groupid = props.activeGroup.groupid;

    await props.deleteGroup(payload);

    setUser([]);
    setEmail("");
    setSelectedList([]);
    props.closeGroupSettingsAction();
  };

  return (
    <div className="modal_box" style={{ display: "flex" }}>
      <div className="modal_container">
        <CrossLogo
          className="modal_crg_cross_logo"
          onClick={() => {
            props.closeGroupSettingsAction();
          }}
        />
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "70px",
            }}
          >
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "30px",
                fontWeight: "bolder",
                marginLeft: "30px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <span style={{ marginRight: "5px" }}>Name:</span>
              <input
                style={{
                  color: "#f1a200",
                  fontWeight: "bolder",
                  fontFamily: "Inter",
                  marginLeft: "5px",
                  display: "inline-block",
                  borderRadius: "10px",
                  textIndent: "20px",
                  outline: "none",
                  border: "1px solid #f1a200",
                  width: "65%",
                }}
                onChange={(e) => {
                  setGroupName(e.target.value);
                }}
                value={groupName}
                className="grp_setting_grp_name"
              />
            </h2>
            <div className="group_dots_wrapper">
              <Dots className="hoverOnDots" />
              <div className="group_edit_dropdown">
                <p
                  onClick={async () => {
                    console.log("Delete group", props.activeGroup.groupid);
                    await handleDeleteGroup();
                  }}
                >
                  Delete
                </p>
              </div>
            </div>
          </div>
          <div className="modal_crg_add_box" style={{ marginTop: "10px" }}>
            <div className="modal_crg_add modal_mobile_view_crg">
              <input
                type="text"
                placeholder="Search by Email or Name"
                value={userEmail}
                onChange={(e) => {
                  handleSearchUser(e.target.value);
                }}
              />
              <div
                style={{
                  overflowY: "auto",
                  width: "90%",
                  maxHeight: "240px",
                  paddingRight: "10px",
                }}
              >
                {userList.map((e, idx) => {
                  return (
                    <div className="search_user_row" key={`user-li-${idx}`}>
                      <Avatar
                        style={{
                          borderRadius: "50%",
                          padding: "2px",
                          border: "solid 3px  rgba(255,255,255,0)",
                          outline: "2px solid  rgba(255,255,255,0)",
                          outlineOffset: "3px",
                          backgroundColor:
                            colorList[hash(e.email.split("@")[0]) % 11],
                          cursor: "pointer",
                          fontSize: "20px",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        {e.email[0].toUpperCase()}
                      </Avatar>
                      <p
                        style={{
                          marginBottom: "0px",
                          flex: 1,
                          marginLeft: "5px",
                          width: "60%",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          fontWeight: "bolder",
                        }}
                      >
                        {e.email}
                      </p>
                      {/* {obj.selected ? (
                        <button className="search_user_remove_button">
                          Remove
                        </button>
                      ) : ( */}
                      <button
                        type="text"
                        className="search_user_add_button"
                        onClick={() => {
                          handleAddUser(e);
                        }}
                      >
                        + Add
                      </button>
                      {/* )} */}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="modal_crg_list">
              <h3>Group Members: {selectedList.length}</h3>
              <div
                style={{
                  overflowY: "auto",
                  width: "80%",
                  height: "240px",
                  paddingRight: "10px",
                }}
              >
                {selectedList.map((obj, idx) => {
                  return (
                    <div className="search_user_row" key={`selected-li-${idx}`}>
                      <Avatar
                        style={{
                          borderRadius: "50%",
                          padding: "2px",
                          border: "solid 3px  rgba(255,255,255,0)",
                          outline: "2px solid  rgba(255,255,255,0)",
                          outlineOffset: "3px",
                          backgroundColor:
                            colorList[hash(obj.email.split("@")[0]) % 11],
                          cursor: "pointer",
                          fontSize: "20px",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        {obj.email[0].toUpperCase()}
                      </Avatar>
                      <p
                        style={{
                          marginBottom: "0px",
                          flex: 1,
                          marginLeft: "10px",
                          width: "60%",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          fontWeight: "bolder",
                        }}
                      >
                        {obj.email}
                      </p>
                      <CancelLogo
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          removeUserEmail(obj);
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <button
                style={{
                  backgroundColor: "#f1a200",
                  border: "none",
                  outline: "none",
                  color: "white",
                  padding: "10px 25px",
                  fontSize: "15px",
                  borderRadius: "15px",
                  cursor: "pointer",
                  margin: "20px 15px",

                  position: "absolute",
                  right: "10px",
                  bottom: "-80px",
                }}
                onClick={() => {
                  handleUpdateGroup();
                }}
              >
                Update Conference Call
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  closeGroupSettingsAction: () => {
    dispatch(showGroupSettings(false));
    dispatch(activateGroup({ groupName: "", members: [], groupid: "" }));
  },
  updateGroup: (payload) => dispatch(updateGroup(payload)),
  deleteGroup: (payload) => dispatch(deleteGroup(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupSetting);

import React, { useEffect, useRef, useState } from "react";
import "../CreateGroup/CreateGroup.css";
import {
  UploadOutlined,
  AudioOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Avatar, notification } from "antd";
// import "./ChatBox.css";
import "./SeniorLineOn.css";
import { connect } from "react-redux";
import {
  autoPlayRecording,
  setNewMsg,
  setNewMsgInGroup,
  showChatBoxAction,
  setUserLineOnInGroup,
  setMissedLineOnGroup,
} from "../../../Redux/Actions/Action";
import {
  activateGroup,
  showRecorder,
  showLineOnPopUp,
} from "../../../Redux/Actions/Action";
import { lineOffAction } from "../../../Redux/Actions/JanusClientActions";
import {
  CrossLogo,
  ChatSend,
  chatLogo,
  TypeLogo,
  MicLogo,
  UploadLogo,
  CancelLogo,
  CancelLogo_01,
  CollapseLogo,
  RaiseHandPng,
  missedCall,
} from "../../../assets/index.js";
import {
  sendMessageAction,
  setLineOn,
  setLineOff,
  createGroup,
} from "../../../Redux/Actions/WsActions";
import ParsedMsg from "../ParsedMsg/ParsedMsg";
// import MimeTypes from "mime-type";
import { joinJanusRoom } from "../../../Redux/Actions/JanusClientActions";
import {
  trackEventActions,
  uploadFileToCloud,
  getDownloadableUrl,
} from "../../../Redux/Actions/firebaseActions";
import { getGroupMessageHistory } from "../../../Redux/Actions/firebaseActions";
import { FileIcon, defaultStyles } from "react-file-icon";
import {
  getLastReadRecording,
  setLastMsgInGroup,
  setLastReadRecording,
} from "../../../Redux/Actions/LocalStorageActions";
import { hash, joinScreenShare } from "../../../logic/common/Utils";
import { message, Spin } from "antd";
import RecordView from "../RecordView/RecordView";
import ChatAudio from "../ChatAudio/ChatAudio";
import CustomPopUp from "../CustomPopUp/CustomPopUp";
import { useCallback } from "react";
import LineOn from "../LineOn/LineOn";
import ImagePreview from "../ImagePreview/ImgePreview";
// random dummy list of user who use this image;
const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const colorList = [
  "#f56a90",
  "#7265e6",
  "#ffbf9f",
  "#00a2ae",
  "#f1a200",
  "#00a2ae",
  "#7265e6",
  "#f56a90",
  "#ffbf9f",
  "#ffbf9f",
  "#f1a200",
];

const onlineStyle = {
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: "#00bf19",
  border: "4px solid #f2fcf3",
  position: "absolute",
  left: "75px",
};
const busyStyle = {
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: "#d63131",
  border: "4px solid #fff0f0",
  position: "absolute",
  left: "75px",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bolder",
  lineHeight: "3",
};

const ImageExt = ["png", "jpeg", "jpg", "heic"];

function ScrollBottom(props) {
  useEffect(() => {
    let ref = props.refrence;
    if (ref.current.scrollHeight > ref.current.clientHeight) {
      setTimeout(() => {
        ref.current.scrollTop =
          ref.current.scrollHeight + ref.current.clientHeight;
      }, 250);
    }
  }, []);
}

function SeniorLineOn(props) {
  const [textMessage, setMessage] = useState("");
  const [fileType, setFileTye] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startingConference, setStartingConference] = useState(false);
  const [lastRecordingObj, setLastRecording] = useState(null);
  const [lastRead, setLastRead] = useState(null);
  const [showRecordPopUp, setViewRecordPopUp] = useState(false);
  const [playingTimestamp, setPlayingTimeStamp] = useState(null);
  const [showContinuePopUp, setShowContinuePopUp] = useState(false);
  const [RecordBtn, setRecordBtn] = useState(true);
  const [cancelBtn, showCancelBtn] = useState(false);
  const [anotherPersonUid, setUid] = useState(null);

  // console.log(props);
  const ref = useRef();
  const input_box_ref = useCallback((node) => {
    console.log("this is chat_input", node);
    if (node) {
      node.focus();
    }
  }, []);

  const handleUpdateLastDate = (timestamp) => {
    // console.log(lastRead, timestamp);
    if (lastRead < timestamp) setLastRead(timestamp);
  };

  const handleIfMissedCall = async () => {
    console.log(props.missedLineOn[props.activeGroup.groupid]);
    if (props.missedLineOn[props.activeGroup.groupid]) {
      // console.log("handle missed call msg");
      let payload = {};
      payload.groupid = props.activeGroup.groupid;
      payload.displayName = props?.user?.displayName;
      payload.type = "MISSEDCALL";
      payload.message = "missed call";
      await props.sendMessageAction(payload);
      props.setMissedToFalse({
        groupid: props.activeGroup.groupid,
        isMissed: false,
      });
    }
  };
  useEffect(() => {
    props.activeGroup.members.forEach((user) => {
      if (user.uid !== props.user.uid) {
        setUid(user.uid);
      }
    });
    let lastRecording = getLastReadRecording(props.user.uid);
    // console.log(lastRecording);
    if (lastRecording) {
      // lastRecording = JSON.parse(lastRecording);

      let lastReadTimestamp = lastRecording?.[props.activeGroup.groupid] ?? 0;
      let timeStampTwoDaysAgo = Date.now() - 2 * 24 * 60 * 60 * 1000;
      if (lastReadTimestamp < timeStampTwoDaysAgo) {
        lastReadTimestamp = timeStampTwoDaysAgo;
        lastRecording[props.activeGroup.groupid] = lastReadTimestamp;
      }

      setLastRecording(lastRecording);
      setLastRead(lastReadTimestamp);
    }
  }, []);

  useEffect(()=>{
    // console.log(props.groupsChats[props.activeGroup.groupid])
    if(!props.activeGroup.istemp && props?.groupsChats?.[props.activeGroup.groupid]?.length)
       setLastMsgInGroup(props.activeGroup.groupid,props.groupsChats[props.activeGroup.groupid][0].createdat,props.user.uid)
  },[])
  // useEffect(() => {
  //   console.log(input_box_ref.current);
  // }, [input_box_ref.current]);

  useEffect(() => {
    if (props.groupsChats[props.activeGroup.groupid] && lastRead) {
      let audioTimestampToPlay = 0;
      // console.log(props.groupsChats[props.activeGroup.groupid], lastRead);
      props.groupsChats[props.activeGroup.groupid].forEach((obj) => {
        if (
          obj.createdat > lastRead &&
          obj.mediaurl &&
          obj.senderuid !== props.user.uid
        )
          audioTimestampToPlay = obj.createdat;
        else return;
      });
      if (audioTimestampToPlay) setPlayingTimeStamp(audioTimestampToPlay);
      else {
        if (props.autoPlayVoiceNotes) {
          let temp_last_Recording = lastRecordingObj ? lastRecordingObj : {};
          temp_last_Recording[props?.activeGroup?.groupid] = lastRead;
          setLastReadRecording(temp_last_Recording, props.user.uid);
          setShowContinuePopUp(true);
        }
      }
    }
  }, [props.groupsChats[props.activeGroup.groupid], lastRead]);

  const handleSendMessage = async () => {
    if (textMessage === "" && !fileName && !fileType) return;

    let payload = {};
    payload.groupid = props.activeGroup.groupid;
    payload.displayName = props?.user?.displayName;
    try {
      if (file && fileName && fileType) {
        if (file.size > 52428800) {
          message.info("file size more than 50mb");
          setFile(null);
          setFileName(null);
          setFileTye(null);
          setMessage("");
          return;
        }
        payload.type = "ATTACHMENT";
        // save the file in cloude storage and get the url.
        setLoading(true);

        const Url = await uploadFileToCloud(file, fileType);
        setLoading(false);
        payload.filename = fileName;
        payload.url = Url;
        payload.fileext = fileType;
        payload.size = file.size;
        if (textMessage) payload.message = textMessage;
        // console.log(payload);
      } else {
        payload.type = "TEXT";

        payload.message = textMessage;
      }

      await props.sendMessageAction(payload);
      if (file && fileName && fileType) {
        setRecordBtn(true);
        showCancelBtn(false);
      }
    } catch (e) {
      console.log(e);
    }
    setFile(null);
    setFileName(null);
    setFileTye(null);
    setMessage("");
    ref.current.scrollTop = ref.current.scrollHeight + ref.current.clientHeight;
  };
  const handleFileChange = (event) => {
    if (!event.target.files[0]) {
      return;
    }
    const fileName = event.target.files[0].name;

    const iconType = fileName.substr(
      fileName.lastIndexOf(".") + 1,
      fileName.length
    );
    setFile(event.target.files[0]);
    setFileName(fileName.substr(0, fileName.lastIndexOf(".")));
    setFileTye(iconType);
    setRecordBtn(false);
  };

  const handleRaiseHand = async () => {
    if (props.activeGroup.groupid) {
      // console.log(props);
      let payload = {};
      payload.groupid = props.activeGroup.groupid;
      payload.displayName = props?.user?.displayName;
      payload.type = "NUDGE";
      payload.message = "NUDGE";
      const seniorName = props.activeGroup.members
        .map((obj) => {
          if (obj.email !== props.user.email) return obj.displayName;
        })
        .filter((o) => o);

      notification.info({
        message: "Success",
        description: `You have successfully requested for a call back. ${seniorName} has been notified.`,
        placement: "topLeft",
        maxCount: 2,
        onClick: () => {},
      });
      await props.sendMessageAction(payload);
    }
  };

  const handleDownloadFile = async (path, filename, fileext) => {
    const url = await getDownloadableUrl(path);

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename + "." + fileext);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleSendMessage();
    }
  };

  function getTime(timestamp) {
    let time = new Date(timestamp);

    let dateParts = time.toDateString().split(" ");
    return (
      dateParts[2] + " " + dateParts[1] + " , " + time.toLocaleTimeString()
    );
  }

  async function JoinRoom() {
    // setShowUnseenPopUp(false);

    if (props.janusRoomJoinning) {
      console.log(
        "room joining process already in room",
        props.janusRoomJoinning
      );
      return;
    }

    await props.joinJanusRoom(props?.activeGroup?.groupid);

    props
      .setLineOn({ groupid: props?.activeGroup?.groupid })
      .then(() => {})
      .catch((e) => console.log(e));
  }

  return props.activeGroup?.members?.length > 2 || props.activeGroup?.istemp ? (
    <LineOn
      closeEverything={() => {
        props.closeChatBoxAction(props?.activeGroup?.groupid);
        props.closeLineOnAction(props?.activeGroup?.groupid);
      }}
    />
  ) : (
    <div className="modal_box" style={{ display: "flex" }}>
      <div
        className="modal_container"
        style={{
          boxShadow: "0 2px 8px 4px rgba(0, 191, 25, 0.6)",
        }}
      >
        {showContinuePopUp ? (
          <CustomPopUp
            message={
              !props.activeGroup?.seniors?.[props.user.uid]
                ? "continue to hand raise"
                : "continue to call"
            }
            closePopUp={() => {
              setShowContinuePopUp(false);
            }}
            handleYes={async () => {
              setShowContinuePopUp(false);

              if (!props.activeGroup?.seniors?.[props.user.uid]) {
                await handleRaiseHand();
                props.closeChatBoxAction(props?.activeGroup?.groupid);
                return;
              } else {
                props.joinJanusRoom(props?.activeGroup?.groupid);
                props
                  .setLineOn({ groupid: props?.activeGroup?.groupid })
                  .then(() => {})
                  .catch((e) => console.log(e));
              }
              props.closeWithoutDeactiveGroup(props?.activeGroup?.groupid);
            }}
            handleNo={() => {
              setShowContinuePopUp(false);
              props.offAutoPlay();
            }}
          />
        ) : null}

        {props.showCollapse ? (
          <CollapseLogo
            className="modal_crg_cross_logo"
            style={{ top: "10px", right: "60px" }}
            onClick={() => {
              props.collpase();
            }}
          />
        ) : null}

        <CrossLogo
          className="modal_crg_cross_logo"
          style={{ top: "0px", right: "10px" }}
          onClick={() => {
            trackEventActions("chatoff", {
              group: props.activeGroup.groupName,
              groupid: props.activeGroup.groupid,
            });
            let temp_last_Recording = lastRecordingObj ? lastRecordingObj : {};
            temp_last_Recording[props?.activeGroup?.groupid] = lastRead;
            setLastReadRecording(temp_last_Recording, props.user.uid);
            if (props.isLineOnBox)
              props.closeLineOnAction(props?.activeGroup?.groupid);
            props.closeChatBoxAction(props?.activeGroup?.groupid);
          }}
        />

        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "25px",
              paddingBottom: "20px",
              boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.06)",
            }}
          >
            {/* <h2 className="chat_box_heading">
              Chat:
              <span
                style={{
                  color: "#f1a200",
                  fontWeight: "bolder",
                  fontFamily: "Inter",
                  marginLeft: "5px",
                }}
              >
                {props.activeGroup.groupName}
              </span>
            </h2> */}
            {props.activeGroup.members
              .filter((user) => user.uid != props.user.uid)
              .slice(0, 1)
              .map((user, idx) => {
                if (props.isLineOnBox) {
                  if (user.uid != props.user.uid) {
                    return (
                      <div
                        key={user.uid + "user" + { idx }}
                        style={{ marginLeft: "40px" }}
                      >
                        {user.photoURL ? (
                          <img
                            src={user.photoURL}
                            style={{
                              height: "60px",
                              width: "60px",
                              padding: "2px",
                              borderRadius: "50%",
                              border:
                                props.plugON &&
                                props.lineOnStatus?.[
                                  props?.activeGroup?.groupid
                                ]?.[user?.uid]
                                  ? "solid 3px #00bf19"
                                  : "solid 3px  rgba(255,255,255,0)",
                              outline:
                                props.plugON &&
                                props.lineOnStatus?.[
                                  props?.activeGroup?.groupid
                                ]?.[user?.uid]
                                  ? "solid 2px #00bf1921"
                                  : "solid 2px rgba(255,255,255,0)",
                              outlineOffset: "3px",
                            }}
                          />
                        ) : (
                          <Avatar
                            style={{
                              borderRadius: "50%",
                              padding: "2px",
                              backgroundClip: "content-box",

                              border:
                                props.plugON &&
                                props.lineOnStatus?.[
                                  props?.activeGroup?.groupid
                                ]?.[user?.uid]
                                  ? "solid 3px #00bf19"
                                  : "solid 3px  rgba(255,255,255,0)",
                              outline:
                                props.plugON &&
                                props.lineOnStatus?.[
                                  props?.activateGroup?.groupid
                                ]?.[user?.uid]
                                  ? "solid 2px #00bf1921"
                                  : "solid 2px rgba(255,255,255,0)",
                              outlineOffset: "3px",
                              backgroundColor:
                                colorList[hash(user.displayName) % 11],
                              cursor: "pointer",
                              fontSize: "30px",
                              width: "60px",
                              height: "60px",
                              lineHeight: "2",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            {user?.displayName?.[0]?.toUpperCase() ?? "@"}
                          </Avatar>
                        )}

                        {props.plugON &&
                        !props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                          user?.uid
                        ] &&
                        props.onlineStatus?.[user?.uid]?.online ? (
                          props.busyStatus?.[user?.uid] ? (
                            <span style={busyStyle} key={idx}>
                              -
                            </span>
                          ) : (
                            <span style={onlineStyle} key={idx}></span>
                          )
                        ) : null}
                        <span
                          style={{
                            display: "inline-block",
                            marginLeft: "10px",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontWeight: "bolder",
                          }}
                        >
                          {user?.displayName ?? user?.name}
                        </span>
                      </div>
                    );
                  }
                } else {
                  if (user.uid == props.user.uid) {
                    return (
                      <div
                        key={user.uid + "user" + { idx }}
                        style={{ marginLeft: "40px" }}
                      >
                        {user.photoURL ? (
                          <img
                            src={user.photoURL}
                            style={{
                              height: "60px",
                              width: "60px",
                              padding: "2px",
                              borderRadius: "50%",
                              border:
                                props.plugON &&
                                props.lineOnStatus?.[
                                  props?.activeGroup?.groupid
                                ]?.[user?.uid]
                                  ? "solid 3px #00bf19"
                                  : "solid 3px  rgba(255,255,255,0)",
                              outline:
                                props.plugON &&
                                props.lineOnStatus?.[
                                  props?.activeGroup?.groupid
                                ]?.[user?.uid]
                                  ? "solid 2px #00bf1921"
                                  : "solid 2px rgba(255,255,255,0)",
                              outlineOffset: "3px",
                            }}
                          />
                        ) : (
                          <Avatar
                            style={{
                              borderRadius: "50%",
                              padding: "2px",
                              backgroundClip: "content-box",

                              border:
                                props.plugON &&
                                props.lineOnStatus?.[
                                  props?.activeGroup?.groupid
                                ]?.[user?.uid]
                                  ? "solid 3px #00bf19"
                                  : "solid 3px  rgba(255,255,255,0)",
                              outline:
                                props.plugON &&
                                props.lineOnStatus?.[
                                  props?.activateGroup?.groupid
                                ]?.[user?.uid]
                                  ? "solid 2px #00bf1921"
                                  : "solid 2px rgba(255,255,255,0)",
                              outlineOffset: "3px",
                              backgroundColor:
                                colorList[hash(user.displayName) % 11],
                              cursor: "pointer",
                              fontSize: "30px",
                              width: "60px",
                              height: "60px",
                              lineHeight: "2",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            {user?.displayName?.[0]?.toUpperCase() ?? "@"}
                          </Avatar>
                        )}

                        <span
                          style={{
                            display: "inline-block",
                            marginLeft: "10px",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontWeight: "bolder",
                          }}
                        >
                          {user?.displayName ?? user?.name}
                        </span>
                      </div>
                    );
                  }
                }
              })}
            {!props.isLineOnBox ? null : props.lineOnStatus?.[
                props?.activeGroup?.groupid
              ]?.[props?.user?.uid] ? (
              <>
                {" "}
                {!props.activeGroup.istemp ? (
                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      padding: "7px 15px",
                      backgroundColor: "#4caf50",
                      color: "white",
                      borderRadius: "10px",
                      // marginBottom: "15px",
                      cursor: "pointer",
                      marginRight: "10px",
                      fontFamily: "Inter",
                      alignSelf: "center",
                    }}
                    onClick={() => {
                      if (startingConference) return;
                      setStartingConference(true);
                      props.startConferenceCall(
                        props.activeGroup.groupid,
                        props.activeGroup.members,
                        props.activeGroup.seniors
                      );
                      props.closeTimer();
                    }}
                  >
                    {startingConference ? <Spin /> : <UserSwitchOutlined />}{" "}
                    Start Conference
                  </button>
                ) : (
                  ""
                )}
                <button
                  style={{
                    border: "none",
                    outline: "none",
                    padding: "7px 15px",
                    backgroundColor: "rgb(49 147 214)",
                    color: "white",
                    borderRadius: "10px",
                    // marginBottom: "15px",
                    cursor: "pointer",
                    marginRight: "10px",
                    fontFamily: "Inter",
                    alignSelf: "center",
                  }}
                  onClick={() => {
                    props.startScreenshare(
                      props.activeGroup.groupid,
                      props?.user?.displayName
                    );
                    joinScreenShare(
                      true,
                      props.activeGroup.groupid,
                      props.activeGroup?.groupName ?? "Conference Call"
                    );
                  }}
                >
                  Share screen
                </button>
                <button
                  style={{
                    border: "none",
                    outline: "none",
                    padding: "7px 15px",
                    backgroundColor: "#d63131",
                    color: "white",
                    borderRadius: "10px",
                    // marginBottom: "15px",
                    cursor: "pointer",
                    marginRight: "90px",
                    fontFamily: "Inter",
                    alignSelf: "center",
                  }}
                  onClick={async () => {
                    props.closeLineOnAction(props.activeGroup.groupid);
                    props.closeTimer();
                    await handleIfMissedCall();
                  }}
                >
                  End Call
                </button>
              </>
            ) : props.onlineStatus?.[anotherPersonUid]?.online ? (
              <button
                style={{
                  border: "none",
                  outline: "none",
                  padding: "7px 35px",
                  backgroundColor: "#f1a200",
                  color: "white",
                  borderRadius: "10px",
                  // marginBottom: "15px",
                  cursor: "pointer",
                  marginRight: "90px",
                  fontFamily: "Inter",
                  alignSelf: "center",
                }}
                onClick={() => {
                  JoinRoom();
                  props.startTimer();
                }}
              >
                Call
              </button>
            ) : (
              <button
                style={{
                  border: "none",
                  outline: "none",
                  padding: "7px 35px",
                  backgroundColor: "#f1a200",
                  color: "white",
                  borderRadius: "10px",
                  // marginBottom: "15px",
                  cursor: "pointer",
                  marginRight: "90px",
                  fontFamily: "Inter",
                  alignSelf: "center",
                }}
                onClick={() => {
                  // JoinRoom();
                  props.showVoiceNote();
                }}
              >
                Voice Note
              </button>
            )}
          </div>
          <div className="modal_crg_add_box" style={{ marginTop: "10px" }}>
            <div
              className="modal_crg_list"
              style={{ height: "95%", transition: " transform 3s 1s" }}
            >
              <div
                style={{
                  overflowY: "auto",
                  width: "90%",
                  maxHeight: "350px",
                  height: "85%",
                  paddingRight: "10px",
                  display: "flex",
                  flexDirection: props.groupsChats?.[
                    props?.activeGroup?.groupid
                  ]?.length
                    ? "column-reverse"
                    : "column",
                }}
                className="chat_box_wrapper"
                ref={ref}
              >
                {props.groupsChats?.[props?.activeGroup?.groupid]?.length ? (
                  <React.Fragment>
                    <div style={{ flex: "1", flexShrink: "1" }}></div>

                    {props.groupsChats[props.activeGroup.groupid].map(
                      (msgObj, idx) => {
                        return (
                          <div
                            key={`slo-${msgObj.groupid}-${msgObj.createdat}`}
                            style={{
                              fontFamily: "Inter",
                              marginBottom: "20px",
                              alignSelf:
                                msgObj.senderuid === props.user.uid
                                  ? "flex-end"
                                  : "flex-start",
                              width: "50%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems:
                                msgObj.senderuid === props.user.uid
                                  ? "flex-end"
                                  : "flex-start",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                color:
                                  colorList[
                                    hash(
                                      msgObj?.senderuid?.split("@")?.[0] ??
                                        msgObj?.senderemail?.split("@")?.[0]
                                    ) % 11
                                  ],
                                marginBottom: "0px",
                                fontWeight: "bolder",
                                fontFamily: "Inter",
                              }}
                            >
                              {msgObj.senderuid === props.user.uid
                                ? "you"
                                : msgObj.sendername
                                ? msgObj.sendername
                                : msgObj.senderemail?.split("@")?.[0]}
                            </p>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#121212",
                                marginBottom: "0px",
                                fontFamily: "Inter",
                                fontWeight: 500,
                                display: "flex",
                                flexDirection: "column",
                                alignItems:
                                  msgObj.senderuid === props.user.uid
                                    ? "flex-end"
                                    : "flex-start",
                              }}
                              className="fileIconMsg"
                            >
                              {msgObj.url ? (
                                ImageExt.includes(
                                  msgObj.fileext.toLowerCase()
                                ) ? (
                                  <ImagePreview
                                    path={msgObj.url}
                                    filename={msgObj.filename}
                                    fileext={msgObj.fileext}
                                  />
                                ) : (
                                  <div
                                    className="uploadedFile"
                                    onClick={() => {
                                      handleDownloadFile(
                                        msgObj.url,
                                        msgObj.filename,
                                        msgObj.fileext
                                      );
                                    }}
                                  >
                                    <FileIcon
                                      extension={msgObj.fileext}
                                      {...defaultStyles[msgObj.fileext]}
                                      color="#f1a2008f"
                                    />
                                  </div>
                                )
                              ) : null}
                              {msgObj.url ? (
                                <React.Fragment>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "#444",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    {msgObj.filename + "." + msgObj.fileext}
                                  </p>
                                  <div
                                    style={{ fontSize: "10px", color: "#aaa" }}
                                  >
                                    Click to Download !
                                  </div>
                                </React.Fragment>
                              ) : null}
                              {msgObj.mediaurl ? (
                                <ChatAudio
                                  url={msgObj.mediaurl}
                                  isSender={msgObj.senderuid === props.user.uid}
                                  lastReadTimestamp={
                                    lastRecordingObj?.[
                                      props.activeGroup.groupid
                                    ]
                                  }
                                  createdat={msgObj.createdat}
                                  setLastRead={(timestamp) => {
                                    handleUpdateLastDate(timestamp);
                                  }}
                                  autoPlay={
                                    props.autoPlayVoiceNotes && playingTimestamp
                                      ? playingTimestamp == msgObj.createdat
                                      : false
                                  }
                                />
                              ) : null}
                              {/* {msgObj.filename ? (
                                <p>{msgObj.filename}</p>
                              ) : null} */}
                              {msgObj.text ? (
                               <p style={{maxWidth:"350px",overflow:"clip",whiteSpace:""}}> <ParsedMsg text={msgObj.text} /></p>
                              ) : null}
                              {msgObj.type == "NUDGE" ? (
                                <img
                                  src={RaiseHandPng}
                                  className={"hoverEffect"}
                                  style={{
                                    width: "64px",
                                    height: "64px",
                                    padding: "8px 12px",
                                    background: "#e6f7e5",
                                    borderRadius: "16px",
                                  }}
                                  draggable="false"
                                ></img>
                              ) : null}
                              {msgObj.type === "MISSEDCALL" ? (
                                <span
                                  style={{
                                    fontSize: "10px",
                                    padding: "5px 15px",
                                    backgroundColor: "#ffc4bc",
                                    color: "red",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <img src={missedCall} width={15}  style={{marginRight:"10px"}}/>
                                  {msgObj.senderuid != props.user.uid?`you missed ${msgObj.sendername} call`:`${props.activeGroup.members.filter((obj)=>obj.uid!=props.user.uid)[0].displayName} missed your call`}
                                  
                                </span>
                              ) : null}
                              {msgObj.type == "SCREENSHARE" ? (
                                <span
                                  style={{
                                    color: "#4972da",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    joinScreenShare(
                                      msgObj.senderuid === props.user.uid,
                                      props.activeGroup.groupid,
                                      props.activeGroup?.groupName ??
                                        "Conference Call"
                                    );
                                  }}
                                >
                                  {msgObj.senderuid != props.user.uid
                                    ? (msgObj.sendername ||
                                        msgObj.displayName ||
                                        "") +
                                      " has shared screen. Click here to view."
                                    : "You shared your screen"}
                                </span>
                              ) : null}
                            </div>
                            <p
                              style={{
                                fontSize: "11px",
                                color: "#121212",
                                fontFamily: "Inter",
                                marginBottom: "0px",
                                opacity: "0.5",
                              }}
                            >
                              {getTime(msgObj.createdat)}
                            </p>
                          </div>
                        );
                      }
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {props.hideLoadingChat?.[
                        props?.activeGroup?.groupid
                      ] ? null : (
                        <button
                          style={{}}
                          className="load_more_btn"
                          onClick={() => {
                            props
                              .findMoreMessage(props?.activeGroup?.groupid)
                              .then(() => {})
                              .catch((e) => {
                                console.log(e);
                              });
                          }}
                        >
                          load more
                        </button>
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <img src={chatLogo} alt="" className="chat_logo" />
                    <span style={{ opacity: 0.6 }}>Say Hello!!</span>
                  </div>
                )}
                {props.newMessage ? <ScrollBottom refrence={ref} /> : null}
              </div>

              {RecordBtn ? (
                <button
                  className="showRecord_btn"
                  style={{
                    backgroundColor: props.lineOnStatus?.[
                      props?.activeGroup?.groupid
                    ]?.[props?.user?.uid]
                      ? "#eee"
                      : "#f1a200",
                  }}
                  onClick={() => {
                    if (
                      props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                        props?.user?.uid
                      ]
                    )
                      return;
                    setRecordBtn(false);
                    showCancelBtn(true);
                    setViewRecordPopUp(true);
                  }}
                >
                  <MicLogo />
                  <span
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                      marginLeft: "10px",
                      fontFamily: "Inter",
                      fontSize: "15px",
                      fontWeight: "normal",
                    }}
                  >
                    Record Voice Note
                  </span>
                </button>
              ) : showRecordPopUp ? null : (
                <React.Fragment>
                  <input
                    className="responsive_chat_input"
                    placeholder="Write something "
                    value={textMessage}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    onKeyDown={handleKeyPress}
                    disabled={showRecordPopUp ? true : false}
                    ref={input_box_ref}
                  />

                  {cancelBtn && !showRecordPopUp ? (
                    <>
                      <ChatSend
                        className="chat_send_logo"
                        onClick={() => {
                          handleSendMessage();
                        }}
                        style={{
                          color:
                            textMessage || fileName ? "#f1a200" : "#121212",
                          opacity: textMessage || fileName ? 1 : 0.3,
                          right: "160px",
                        }}
                      />
                      <label
                        className="upload_label"
                        style={{
                          right: "115px",
                          backgroundColor: "#f7f7f7",
                        }}
                        onClick={() => {
                          // setRecordBtn(false);
                        }}
                      >
                        <UploadLogo className="upload_btn" />

                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleFileChange(e);
                          }}
                        />
                      </label>
                    </>
                  ) : (
                    <ChatSend
                      className="chat_send_logo"
                      onClick={() => {
                        handleSendMessage();
                      }}
                      style={{
                        color: textMessage || fileName ? "#f1a200" : "#121212",
                        opacity: textMessage || fileName ? 1 : 0.3,
                      }}
                    />
                  )}
                </React.Fragment>
              )}

              {fileType ? (
                <div className="fileIcon">
                  {loading ? <Spin /> : null}
                  <CrossLogo
                    style={{
                      alignSelf: "flex-end",
                      display: loading ? "none" : "block",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFile(null);
                      setFileName(null);
                      setFileTye(null);
                      setMessage("");
                      setRecordBtn(true);
                    }}
                  />
                  <FileIcon
                    extension={fileType}
                    {...defaultStyles[fileType]}
                    style={{ height: "50px", width: "30px" }}
                    color="#f1a2008f"
                  />
                  <p
                    style={{
                      color: "#f1a200",
                      width: "70%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {fileName}
                  </p>
                </div>
              ) : null}

              {cancelBtn ? (
                <CancelLogo_01
                  className="cancel_btn_01"
                  onClick={() => {
                    showCancelBtn(false);
                    setRecordBtn(true);
                    setViewRecordPopUp(false);
                  }}
                />
              ) : (
                <>
                  <label
                    className="upload_label"
                    onClick={() => {
                      // setRecordBtn(false);
                    }}
                  >
                    <UploadLogo className="upload_btn" />

                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  </label>

                  <label
                    className="audio_label"
                    onClick={() => {
                      setRecordBtn(false);
                      showCancelBtn(true);
                    }}
                  >
                    <TypeLogo className="voice_btn" />
                  </label>
                </>
              )}
              {showRecordPopUp ? (
                <RecordView
                  closeRecordPopUp={() => {
                    // props.hideRecorder();
                    let seniorName = null;
                    props.activeGroup.members.map((user) => {
                      if (user.uid != props.user.uid) {
                        seniorName = user?.displayName ?? user?.name;
                        console.log(user?.displayName ?? user?.name);
                      }
                    });
                    // setExpand(true);
                    notification.info({
                      message: "Success",
                      description: `Voice note sent to ${seniorName}`,
                      placement: "topLeft",
                      maxCount: 5,
                      onClick: () => {},
                    });

                    setViewRecordPopUp(false);
                    setRecordBtn(true);
                    showCancelBtn(false);
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  startConferenceCall: async (groupid, members, seniors) => {
    await dispatch(
      createGroup({
        parentGroupid: groupid,
        istemp: true,
        conversation: false,
        seniors: {},
        members: members,
      })
    );
  },
  closeChatBoxAction: (groupid) => {
    dispatch(setNewMsgInGroup({ [groupid]: false }));
    dispatch(showChatBoxAction(false));
    dispatch(activateGroup({ groupName: "", members: [], groupid: "" }));
    dispatch(showRecorder(false));
    dispatch(autoPlayRecording(false));
    dispatch(showLineOnPopUp(false));
  },
  closeLineOnAction: (groupid) => {
    dispatch(setNewMsgInGroup({ [groupid]: false }));
    dispatch(setLineOff({ groupid: groupid }));
    dispatch(lineOffAction(groupid, false));
    dispatch(setUserLineOnInGroup(null));
  },
  startScreenshare: (groupid, displayName) => {
    let payload = {};
    payload.groupid = groupid;
    payload.displayName = displayName;
    payload.type = "SCREENSHARE";
    payload.message = "SCREENSHARE";
    dispatch(sendMessageAction(payload));
  },
  offAutoPlay: () => {
    dispatch(autoPlayRecording(false));
  },
  closeWithoutDeactiveGroup: (groupid) => {
    dispatch(setNewMsgInGroup({ [groupid]: false }));
    dispatch(showChatBoxAction(false));

    dispatch(showRecorder(false));
    dispatch(autoPlayRecording(false));
  },
  joinJanusRoom: (groupid) => dispatch(joinJanusRoom(groupid)),
  setLineOn: (payload) => dispatch(setLineOn(payload)),
  setMissedToFalse: (payload) => dispatch(setMissedLineOnGroup(payload)),
  sendMessageAction: (payload) => {
    return dispatch(sendMessageAction(payload));
  },
  setMessage: (groupid) => {
    dispatch(setNewMsg({ [groupid]: false }));
  },
  findMoreMessage: (groupid) => {
    return dispatch(getGroupMessageHistory(groupid, true));
  },
  // showRecorder: () => {
  //   dispatch(showRecorder(true));
  // },
  showVoiceNote: () => {
    dispatch(showLineOnPopUp(false));
    dispatch(showRecorder(true));
  },
  hideRecorder: () => {
    dispatch(showRecorder(false));
  },
  //   makeRequest: (type, payload) => dispatch(makeRequest(type, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeniorLineOn);

import React, { useEffect } from "react";
import { CrossLogo } from "../../../assets";
export default function CustomPopUp(props) {
  useEffect(() => {
    console.log(props.show);
  }, [props.show]);
  return (
    <div className="confirmation_container">
      <div className="confirmation_box">
        {/* <CrossLogo
          style={{ alignSelf: "flex-end", cursor: "pointer" }}
          onClick={() => {
            props.closePopUp();
          }}
        /> */}
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bolder",
            padding: "25px",
            fontFamily: "Inter",
            marginBottom: "10px",
            alignSelf: "flex-start",
            color: props?.color ?? "black",
          }}
        >
          {props.message}
        </p>
        <div style={{ alignSelf: "flex-end", marginBottom: "10px" }}>
          {props.handleNo ? (
            <button
              className="confirmation_btn no_color"
              onClick={() => {
                props.handleNo();
              }}
            >
              No
            </button>
          ) : null}
          {props.handleYes ? (
            <button
              className="confirmation_btn  green_btn"
              onClick={() => {
                props.handleYes();
              }}
            >
              Yes
            </button>
          ) : null}
          {
            props.refreshBtn?<button
            className="confirmation_btn  green_btn"
            onClick={() => {
             window.location.reload(true)
            }}
          >
            Refresh
          </button>:null
          }
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Avatar,
  Switch,
  notification,
  Spin,
  Divider,
  Tooltip,
  Button,
} from "antd";
import notificationBell from "../../../sounds/notification.mp3";
import Loading from "./Loading.jsx";
import LogRocket from "logrocket";
import CreateGroup from "../../Components/CreateGroup/CreateGroup";
// import Group from "../../Components/Group/Group";
import Groups from "../../Components/Group/Groups";
import GroupSetting from "../../Components/GroupSetting/GroupSetting";
import LineOn from "../../Components/LineOn/LineOn.jsx";
import NewConversation from "../../Components/NewConversation/NewConversation.jsx";
import SeniorLineOn from "../../Components/SeniorLineOn/SeniorLineOn";
import JuniorsStats from "../../Components/juniorsStats/juniorsStats";
import {
  getLastVisitDay,
  setLastVisitDay,
} from "../../../Redux/Actions/LocalStorageActions";
import {
  setPlugOn,
  setPlugOff,
  disconnectWs,
  getUnreadMsgOfTopGroups,
} from "../../../Redux/Actions/WsActions.js";
import {
  plugLogo as plugApp,
  searchLogo,
  cubeLogo,
  userImage,
  copyRight,
  AddLogo,
  LoaderLogo,
  CrossLogo,
  AlertLogo,
} from "../../../assets/index.js";

import "./Home.css";
import {
  showCreateGroup,
  plugON,
  showNewConversation,
  setUserOnlineTime,
  setOnlineStatus,
} from "../../../Redux/Actions/Action";

import { connect } from "react-redux";
import firebase from "firebase/compat/app";

import {
  initializeSocketClient,
  subscribeAction,
  socketIsConnected,
} from "../../../Redux/Actions/WsActions.js";
import {
  initializeJanusClient,
  disconnectJanus,
} from "../../../Redux/Actions/JanusClientActions.js";
import ChatBox from "../../Components/ChatBox/ChatBox.jsx";
import { checkIsSameDay } from "../../../logic/common/Utils.js";
import ConfirmationPopUp from "../../Components/ConfirmationPopUp/ConfirmationPopUp";
import SeniorLineOnWrapper from "../../Components/SeniorLineOnWrapper/SeniorLineOnWrapper";
import JuniorLineOnWrapper from "../../Components/JuniorLineOnWrapper/JuniorLineOnWrapper";
import DisconnectedFallback from "../../Components/DisconnectedFallback/DisconnectedFallback";
import CustomPopUp from "../../Components/CustomPopUp/CustomPopUp";
import HandRaisedPopUp from "../../Components/HandRaisedPopUp/HandRaisedPopUp";
import { hash } from "../../../logic/common/Utils.js";

const colorList = [
  "#f56a90",
  "#7265e6",
  "#ffbf9f",
  "#00a2ae",
  "#f1a200",
  "#00a2ae",
  "#7265e6",
  "#f56a90",
  "#ffbf9f",
  "#ffbf9f",
  "#f1a200",
];
function Notification(props) {
  useEffect(() => {
    if (props.status === "created") {
      notification.info({
        message: `group ${props.status}`,
        description: `Refresh page `,
        placement: "top",
        duration: 0,
        maxCount: 1,
        btn: (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              window.location.reload(true);
            }}
          >
            Reload
          </Button>
        ),
        onClick: () => {},
      });
    } else {
      notification.info({
        message: `group ${props.status}`,
        description: `Refresh page `,
        placement: "top",
        maxCount: 5,
        onClick: () => {},
      });
    }
  }, []);

  return null;
}

function NewMsgNotification(props) {
  useEffect(() => {
    let notificationAudio = new Audio(notificationBell);
    notification.info({
      message: `New Message`,
      description: props.message,
      placement: "top",
      maxCount: 2,
      onClick: () => {},
    });
    try {
      notificationAudio
        .play()
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return null;
}

let _lasthidetime = 0;
function isElectron() {
  // Renderer process
  if (
    typeof window !== "undefined" &&
    typeof window.process === "object" &&
    window.process.type === "renderer"
  )
    return true;
  // Main process
  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!process.versions.electron
  )
    return true;
  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  )
    return true;
  return false;
}

function Home(props) {
  // console.log("rendering home");
  const plugOn = props.plugON;
  // console.log(props);
  const [showMsg, setShowMsg] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [noMic, setNoMic] = useState(false);
  const [plugOnSpinner, showPlugOnSpinner] = useState(false);
  const [startBtn, setStartBtn] = useState(false);

  const switchRef = useRef();

  // handling switch on and off.
  const handleSwitchChange = async () => {
    if (!plugOn) {
      showPlugOnSpinner(true);
      await props.setPlugOn();
      showPlugOnSpinner(false);
      window.onbeforeunload = () => {
        props.disconnectWs();
        return null;
      };
    } else {
      window.onbeforeunload = null;
      props.disconnectJanus();
      await props.setPlugOff();
      setShowMsg(true);
    }
  };

  useEffect(() => {
    if (
      props.groupsSubscribed &&
      props.userSubscribed &&
      props.signalSubscribed
    ) {
      props
        .getUnreadMsgOfTopGroups()
        .then(() => {
         
        })
        .catch((err) => console.log(err));
    }
  }, [props.groupsSubscribed, props.userSubscribed, props.signalSubscribed]);

  useEffect(() => {
    console.log("init");

    setStartBtn(
      !checkIsSameDay(new Date().getTime(), getLastVisitDay(props.user.uid))
    );
    LogRocket.init("gnusd1/plugapp");
    LogRocket.identify(props.user.email, {
      name: props.user.displayName,
      email: props.user.email,
    });
    window.STREAM = null;

    function getNewMicTrack() {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(function (stream) {
          console.log("You let me use your mic!");
          window.STREAM = stream;
          const audioTrack = stream.getAudioTracks()[0];
          console.log(audioTrack);
          window.STREAM.oninactive = () => {
            console.log("Stream became inactive");
            // getNewMicTrack();
            // console.log(window.STREAM)
          };
          // console.log(audioTrack);
          // audioTrack[0].enable = false;
        })
        .catch(function (err) {
          console.log("No mic for you!");
          setNoMic(true);
        });
    }
    getNewMicTrack();

    window.onbeforeunload = () => {
      props.disconnectWs();
      return null;
    };
    setTimeout(() => {
      window.onbeforeunload = null;
    }, 1000);
    // window.showNotification = (show) => {
    //   if (show) {
    //     console.log("show Notification");
    //     notification.info({
    //       message: `janus Disconected`,
    //       description: `Connecting to janus please wait!!`,
    //       placement: "top",
    //       maxCount: 5,
    //       onClick: () => {},
    //     });
    //     setShowSpinner(true);
    //   } else {
    //     notification.info({
    //       message: `janus Connected`,
    //       description: `Janus connection On`,
    //       placement: "top",
    //       maxCount: 5,
    //       onClick: () => {},
    //     });
    //     setShowSpinner(false);
    //   }
    // };
    async function updateOnlineTimeTimer() {
      props.setUserOnlineTimeAction(props.store.getState().onlineStatus);
    }

    setInterval(updateOnlineTimeTimer, 15000);

    async function isScoketConnectedTimer() {
      let connected = socketIsConnected();
      console.log("is Socket Connected " + connected);
      if (!connected) {
        await props.initializeSocketClient(props.store);
        await props.initializeJanusClient();
      }
    }

    setInterval(isScoketConnectedTimer, 30000);

    async function janusAndSocketInit() {
      await props.initializeSocketClient(props.store);

      await props.initializeJanusClient();
    }

    document.addEventListener("visibilitychange", async (ev) => {
      console.log(`Tab state : ${document.visibilityState}`);
      if (document.visibilityState === "visible") {
        //  check if the accessToken has been expired.
        if (_lasthidetime && Date.now() - _lasthidetime > 3600000) {
          await props.initializeSocketClient(props.store);
          await props.initializeJanusClient();
        } else if (
          props.user.stsTokenManager.expirationTime - new Date().getTime() <=
          0
        ) {
          await props.initializeSocketClient(props.store);
          await props.initializeJanusClient();
        }
      } else {
        _lasthidetime = Date.now();
      }
    });

    janusAndSocketInit();

    return () => {
      window.onbeforeunload = null;
      document.documentElement.style.setProperty("--color-plug", "#797979");
      props.disconnectWs();
    };
  }, []);

  return (
    <span
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Row
        style={{
          width: "100%",
          backgroundColor: props.plugON ? "#00bd1921" : "#f7f7f7",
          borderBottom: props.plugON
            ? "solid 2px #00bf1950"
            : "solid 2px #f7f7f7",
        }}
      >
        <Col span={24}>
          <div className="main-header">
            <img
              src={plugApp}
              draggable="false"
              className="plugApp_logo"
              onClick={() => {
                window.location.reload(true);
              }}
            ></img>
            <Switch
              ref={switchRef}
              checked={plugOn}
              className="switch"
              size="large"
              style={
                plugOn
                  ? { backgroundColor: "#00bd1921" }
                  : { backgroundColor: "#f7f7f7" }
              }
              checkedChildren={
                <span
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    opacity: 1,
                    position: "relative",
                  }}
                >
                  available
                </span>
              }
              unCheckedChildren={
                <span
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  busy
                  {plugOnSpinner ? (
                    <LoaderLogo className="plug_on_loader spinner" />
                  ) : null}
                </span>
              }
              onChange={handleSwitchChange}
            />

            {!plugOn && showMsg ? (
              <div
                onClick={() => {
                  return;
                }}
                className="plugon_msg"
              >
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: "15px",
                    marginRight: "5px",
                  }}
                >
                  "Mark yourself available"
                </span>
                during your work hours to have real-time conversations with your
                teammates
              </div>
            ) : null}
            {plugOn ? <JuniorsStats /> : null}

            <div style={{ flex: 1 }}></div>
            {/* <div className="header-serach">
              <img src={searchLogo} draggable="false"></img>
            </div> */}
            {/* <div className="add_logo_container">
              <AddLogo className="add_logo" />
              <div className="add_logo_dropdown">
                <p
                  onClick={() => {
                    console.log("new conv. is called");
                    props.showNewConversation(true);
                  }}
                >
                  New Conversation
                </p>
                <p
                  onClick={() => {
                    console.log("new conv. is called");
                    props.showCreateGroupAction(true);
                  }}
                >
                  Create Group
                </p>
              </div>
            </div> */}
            {/* <div
              className="header-create-grp"
              onClick={() => {
                if (!props.plugON) return;
                props.showCreateGroupAction(true);
              }}
              style={{ opacity: props.plugON ? "1" : "0.5" }}
            >
              <img src={cubeLogo} draggable="false"></img>
              <span>create group</span>
            </div> */}
            <div className="user_img">
              <Avatar
                style={{
                  backgroundColor: "#f1a200",
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  fontSize: "20px",
                  lineHeight: "2.2",
                }}
                size="large"
              >
                {props?.user?.displayName?.[0] || props?.user?.email?.[0]}
              </Avatar>
              <div className="user_drop_menu">
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: "13px",
                    padding: "1px 10px",
                  }}
                >
                  {props?.user?.displayName ||
                    props?.user?.email?.split("@")?.[0]}
                </span>
                <span style={{ paddingLeft: "10px" }}>
                  {props?.user?.email}
                </span>
                <span
                  className="logout_"
                  onClick={() => {
                    firebase
                      .auth()
                      .signOut()
                      .then(() => {
                        window.onbeforeunload = null;
                        props.disconnectJanus();
                        props.disconnectWs();
                        window.location.reload();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >
                  Log Out
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {plugOnSpinner ? (
        <div className="plug_on_spin">
          <LoaderLogo className="spinner" />
        </div>
      ) : null}

      {startBtn ? (
        <div className="start_container">
          <div className="start_day">
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                padding: "0px 15px",
                marginBottom: "10px",
              }}
            >
              {props.user.photoURL ? (
                <img
                  src={props.user.photoURL}
                  style={{ borderRadius: "50%", padding: "2px", width: "50px" }}
                />
              ) : (
                <Avatar
                  style={{
                    borderRadius: "50%",
                    padding: "2px",
                    backgroundClip: "content-box",
                    backgroundColor:
                      colorList[
                        hash(props.user?.displayName ?? "@someone") % 11
                      ],

                    cursor: "pointer",
                    fontSize: "22px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  className="group_user_avatar"
                >
                  {props.user?.displayName?.[0]?.toUpperCase() ?? "@"}
                </Avatar>
              )}
              <div>
                <p style={{ margin: 0, fontWeight: "bolder" }}>
                  {props.user.displayName}
                </p>
                <p style={{ margin: 0, fontWeight: "bolder" }}>
                  {props.user.email}
                </p>
                <p style={{ margin: 0, fontWeight: "bolder" }}>
                  {new Date().toDateString()}
                </p>
              </div>
            </div>
            <button
              className="start_btn"
              onClick={() => {
                switchRef.current.click();
                setLastVisitDay(props.user.uid);
                setStartBtn(false);
              }}
            >
              Start Your Day
            </button>
          </div>
        </div>
      ) : null}

      {noMic ? (
        <CustomPopUp
          message="Allow mic permission and refresh page"
          handleNo={null}
          handleYes={null}
          refreshBtn={true}
          color="#f1a200"
        />
      ) : null}

      {props.showHandRaisePopUp ? <HandRaisedPopUp /> : null}
      {props.showCreateGroup ? <CreateGroup /> : null}
      {props.showLineOnPopUp ? <SeniorLineOnWrapper /> : null}
      {props.showGroupSettings ? <GroupSetting /> : null}
      {/* {props.showChatBox ? <ChatBox /> : null} */}
      {props.showChatBox ? <SeniorLineOn /> : null}
      {props.showAudioRecorder ? <JuniorLineOnWrapper /> : null}
      {props.showNewConversationPopUp ? <NewConversation /> : null}
      {props.newGroup ? <Notification status={props.newGroup} /> : null}
      {props.showSeniorsConfirmation ? <ConfirmationPopUp /> : null}

      {props.isSocketConnected === "disconnected" ? (
        <DisconnectedFallback />
      ) : null}
      {props.newMessage &&
      props.showChatBox !== props.newMessage &&
      props.showLineOnPopUp !== props.newMessage &&
      !props.showAudioRecorder ? (
        <NewMsgNotification message={props.newMessageContent} />
      ) : null}
      {showSpinner ? (
        <Spin style={{ position: "absolute", fontSize: "50px" }} />
      ) : null}
      <div
        className="groups_container"
        style={{ opacity: props.plugON ? 1 : 0.2 }}
      >
        {props.groups.length === 0 ? (
          <>
            {props.groupExist ? (
              <React.Fragment>
                <Loading />
                <Loading />
              </React.Fragment>
            ) : (
              <div
                style={{
                  height: "60vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "40px",
                }}
              >
                <p className="no_group_msg">
                  Please join at{" "}
                  <a href="https://getmeplugged.in/">getmeplugged.in</a>. plug
                  is an online walkie talkie and will help us communicate easily
                  during our working hours.
                  <br />
                  <br />
                  Join now{" "}
                  <a href="https://getmeplugged.in">https://getmeplugged.in</a>
                </p>

                <button
                  className="no_group_invite_btn"
                  onClick={() => {
                    window.onbeforeunload = null;

                    window.location.href =
                      "mailto:?subject=Invitation to join Plug - your online walkie talkie.&body=Please join at plugapp.net. plug is an online walkie talkie and will help us communicate easily during our working hours.%0D%0A %0D%0AJoin now https://plugapp.net";

                    window.onbeforeunload = () => {
                      props.disconnectWs();
                      return null;
                    };
                  }}
                >
                  Invite More Members
                </button>
              </div>
            )}
          </>
        ) : (
          <Groups />
        )}

        <div style={{ flex: 1, maxHeight: "45vh" }}></div>
        <div style={{ textAlign: "center" }}>
          copyright &copy; {new Date().getFullYear()}
        </div>

        <div className="refresh_container">
          {/* <div className="user_img_mobile">
          <Avatar
            style={{
              backgroundColor: "#f1a200",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              fontSize: "20px",
              lineHeight: "2.2",
              boxShadow: "2px 5px 10px rgba(0, 0, 0, 0.4)",
            }}
            size="large"
          >
            {props?.user?.displayName?.[0] || props?.user?.email?.[0]}
          </Avatar>
          <div className="user_drop_menu_mobile">
            <span
              style={{
                fontWeight: "bolder",
                fontSize: "13px",
                padding: "1px 10px",
              }}
            >
              {props?.user?.displayName || props?.user?.email?.split("@")?.[0]}
            </span>
            <span style={{ paddingLeft: "10px" }}>{props?.user?.email}</span>
            <span
              className="logout_"
              onClick={() => {
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    window.onbeforeunload = null;
                    props.disconnectJanus();
                    props.disconnectWs();
                    window.location.reload();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Log Out
            </span>
          </div>
        </div> */}

          <div
            onClick={() => {
              window.location.reload(true);
            }}
            style={{
              cursor: "pointer",
              borderBottom: "1px dotted black",
              lineHeight: "1.2",
            }}
          >
            &#9888; If you are facing any issues, click here to reload app
          </div>
        </div>
      </div>
    </span>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  plugOnAction: (payload) => dispatch(plugON(payload)),
  showCreateGroupAction: (payload) => dispatch(showCreateGroup(payload)),
  showNewConversation: (payload) => {
    dispatch(showNewConversation(payload));
  },

  initializeSocketClient: (store) => {
    return dispatch(initializeSocketClient(store));
  },
  initializeJanusClient: () => {
    return dispatch(initializeJanusClient());
  },
  subscribeAction: (subscribeTo) => {
    dispatch(subscribeAction(subscribeTo));
  },
  setPlugOn: () => {
    return dispatch(setPlugOn());
  },
  setPlugOff: () => {
    return dispatch(setPlugOff());
  },
  disconnectWs: () => {
    dispatch(disconnectWs());
  },
  disconnectJanus: () => {
    dispatch(disconnectJanus());
  },
  setUserOnlineTimeAction: (onlineStatus) => {
    dispatch(setUserOnlineTime(onlineStatus));
  },
  getUnreadMsgOfTopGroups: () => dispatch(getUnreadMsgOfTopGroups()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);

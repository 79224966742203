import React from "react";
import { connect } from "react-redux";
import { CrossLogo, ExpandLogo } from "../../../assets";
import { activateGroup, showRecorder } from "../../../Redux/Actions/Action";
// import "./SeniorLineOnWrapper.css";
import { useState } from "react";
import SeniorLineOn from "../SeniorLineOn/SeniorLineOn";
import RecordView from "../RecordView/RecordView";
import { useEffect } from "react";
import { notification } from "antd";
import { useRef } from "react";

const colorList = [
  "#f56a90",
  "#7265e6",
  "#ffbf9f",
  "#00a2ae",
  "#f1a200",
  "#00a2ae",
  "#7265e6",
  "#f56a90",
  "#ffbf9f",

  "#ffbf9f",
  "#f1a200",
];

function JuniorLineOnWrapper(props) {
  const [expand, setExpand] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);
  // const ref = useRef();
  // useEffect(() => {
  //   console.log(ref.current);
  //   if (ref.current) {
  //     ref.current.style.animation = "confirmationBoxAnimation 0.5s linear 1";
  //   }
  // }, [ref.current]);
  return (
    <React.Fragment>
      {expand ? (
        <SeniorLineOn
          isLineOnBox={false}
          collpase={() => {
            setExpand(false);
          }}
          showCollapse={true}
        />
      ) : (
        <div className="senior_wrapper_container">
          <div
            className="senior_wrapper_box"
            style={{
              animation: showAnimation
                ? "confirmationBoxAnimation 0.5s linear 1"
                : "",
            }}
          >
            <div
              style={{
                alignSelf: "flex-end",
                marginRight: "5px",
                marginTop: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ExpandLogo
                style={{ height: "20px", cursor: "pointer" }}
                onClick={() => {
                  setExpand(true);
                  setShowAnimation(false);
                }}
              />
              <CrossLogo
                style={{ height: "40px", cursor: "pointer" }}
                onClick={() => {
                  // close the voice note
                  props.closeJuniorWrapper(props?.activeGroup?.groupid);
                }}
              />
            </div>
            <RecordView
              isAutoRecording={true}
              closeRecordPopUp={() => {
                let seniorName = null;
                props.activeGroup.members.map((user) => {
                  if (user.uid != props.user.uid) {
                    seniorName = user?.displayName ?? user?.name;
                    console.log(user?.displayName ?? user?.name);
                  }
                });
                // setExpand(true);
                notification.info({
                  message: "Success",
                  description: `Voice note sent to ${seniorName}`,
                  placement: "topLeft",
                  maxCount: 5,
                  onClick: () => {},
                });

                props.closeJuniorWrapper(props?.activeGroup?.groupid);
              }}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  closeJuniorWrapper: () => {
    dispatch(activateGroup({ groupName: "", members: [], groupid: "" }));
    dispatch(showRecorder(false));
  },

  //   makeRequest: (type, payload) => dispatch(makeRequest(type, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JuniorLineOnWrapper);

import React from "react";

export default function Loading() {
  const style1 = {
    height: "30px",
    width: "30%",
    backgroundColor: "#f5f5f5",
    marginBottom: "20px",
    marginLeft: "16px",
  };
  const style2 = {
    height: "132px",

    display: "flex",
    marginBottom: "64px",
  };
  const style3 = {
    width: "132px",
    backgroundColor: "#f5f5f5",
    marginLeft: "20px",
    borderRadius: "12px",
  };

  return (
    <div>
      <div style={style1}></div>
      <div style={style2}>
        <div
          style={{ flex: 1, backgroundColor: "#f5f5f5", borderRadius: "12px" }}
        ></div>
        <div style={style3}></div>
        <div style={style3}></div>
      </div>
    </div>
  );
}

import { PlugWsClient } from "../../logic/modules/PlugWSClient/index";
import * as Actions from "./Action";
import * as JanusActions from "./JanusClientActions.js";
import * as localStorageAction from "./LocalStorageActions.js";
import {
  getGroupMessageHistory,
  getGroupsLastMessage,
  getTokenActions,
  trackEventActions,
} from "./firebaseActions";
import { delay } from "../../logic/common/Utils";

let plugWsClientInstance = new PlugWsClient();

const socketConnected = (payload) => {
  return {
    type: "SOCKET_CONNECTED",
    payload: payload,
  };
};

const subscribing = (payload) => {
  return {
    type: "SUBSCRIBING",
    payload: payload,
  };
};

export const initializeSocketClient = (store) => {
  return async (dispatch) => {
    await plugWsClientInstance.init(
      store,
      Actions,
      {
        setPlugOff,
        setPlugOn,
        setLineOn,
        setLineOff,
        customGroupSubscribe,
      },
      JanusActions,
      localStorageAction,
      getTokenActions
    );
    dispatch(socketConnected(true));
  };
};

let count = 0;

export const subscribeAction = (subscribeTo) => {
  return async (dispatch) => {
    dispatch(subscribing(true));
    await plugWsClientInstance.subscribe(subscribeTo);
    dispatch(subscribing(false));
  };
};

export const setLineOn = (payload) => {
  return async (dispatch, getState) => {
    await plugWsClientInstance.makeRequest("signals", "setLineOn", payload);
    let { groupid, groupName } = getState().activeGroup;
    console.log(groupid, groupName);
    trackEventActions("lineon", { groupid: groupid, group: groupName });
  };
};
export const setLineOff = (payload) => {
  return async (dispatch, getState) => {
    let { groupid, groupName } = getState().activeGroup;
    dispatch(Actions.setPendingLineOff(groupid));
    await plugWsClientInstance.makeRequest("signals", "setLineOff", payload);

    console.log(groupid, groupName);
    trackEventActions("lineoff", { groupid: groupid, group: groupName });
  };
};

export const socketIsConnected = () => {
  return plugWsClientInstance.isConnected();
};

export const setPlugOn = (auto) => {
  return async (dispatch, getState) => {
    await plugWsClientInstance.subscribe("users");
    await plugWsClientInstance.subscribe("signals");
    dispatch(Actions.userPlugginOn(true));
    await plugWsClientInstance.makeRequest("users", "setUserOnline", null);
    dispatch(Actions.userPlugginOn(false));
    dispatch(Actions.plugON({ plug: true, bgColor: "#00bf1970" }));
    document.documentElement.style.setProperty("--color-plug", "#00bf19");
    trackEventActions("plugon", { auto });
  };
};

export const reAuthenticate = (accessToken, user) => {
  return (dispatch) => {
    // console.log(accessToken);
    plugWsClientInstance.reAuthenticate(accessToken, user);
  };
};

export const setPlugOff = () => {
  return async (dispatch) => {
    await plugWsClientInstance.makeRequest("users", "setUserOffline", null);
    dispatch(Actions.plugON({ plug: false, bgColor: "rgba(0,0,0,0.3)" }));
    dispatch(Actions.userSubscribed(false));
    dispatch(Actions.signalSubscribed(false));
    dispatch(Actions.clearAllChats())
    document.documentElement.style.setProperty("--color-plug", "#797979");
    trackEventActions("plugoff");
  };
};

export const customGroupSubscribe = (payload) => {
  return async (dispatch) => {
    await plugWsClientInstance.makeRequest(
      "groups",
      "customSubscribe",
      payload
    );
  };
};

export const createGroup = (payload) => {
  return async (dispatch) => {
    await plugWsClientInstance.makeRequest("groups", "createGroup", payload);
  };
};

export const updateGroup = (payload) => {
  return async (dispatch) => {
    await plugWsClientInstance.makeRequest("groups", "updateGroup", payload);
  };
};
export const deleteGroup = (payload) => {
  console.log("Delete group", payload);
  return async (dispatch) => {
    console.log("Delete group", payload);
    await plugWsClientInstance.makeRequest("groups", "deleteGroup", payload);
  };
};

export const disconnectWs = (payload) => {
  return async (dispatch, getState) => {
    try {
      await plugWsClientInstance.disconnect();
    } catch (e) {
      console.log(e);
    }

    console.log("disconnected socket");
  };
};

export const sendMessageAction = (payload) => {
  return async (dispatch) => {
    console.log(payload);
    await plugWsClientInstance.makeRequest("groups", "sendMessage", payload);
    // do something
  };
};

export const getLoginActivity = async (payload) => {
  return plugWsClientInstance.makeRequest("users", "loginActivity",payload);
};



export const getUnreadMsgOfTopGroups = () => {
  return async (dispatch, getState) => {
    const groups = getState().groups.slice(0, 10);
    const uid=getState().user.uid;
    const lastReadRecordingMap = localStorageAction.getLastMsgInGroup(uid);
    // console.log(lastReadRecordingMap)
    groups.forEach(async (group) => {
      const timestamp = await dispatch(getGroupsLastMessage(group.groupid));
        
        // console.log(timestamp, lastReadRecordingMap[group.groupid]);
        if (timestamp && timestamp > Date.now() - 2 * 24 * 60 * 60 * 1000) {
          let lastReadRecording = lastReadRecordingMap[group.groupid];
          if (!lastReadRecording) lastReadRecording = 1;
          if (timestamp > lastReadRecording) {
            dispatch(Actions.setNewMsgInGroup({ [group.groupid]: "MEDIA" }));
          }
        }
    });
    // Promise.all(
    //   groups.map(async (group) => {
        
    //   })
    // );
  };
};

import React, { useEffect, useState } from "react";
import notificationBell from "../../../sounds/notification.mp3";
import { notification } from "antd";
import { createJanusHandle } from "../../../Redux/Actions/JanusClientActions";
import { connect } from "react-redux";

function Notification(props) {
  useEffect(() => {
    let notificationAudio = new Audio(notificationBell);

    props.createJanusConnections(props.groupid, null, props.showLineOnPopUp);

    notificationAudio.volume = 0.1;
    const notify = (userName, groupName, senior) => {
      notification.info({
        message: senior ? "Called" : "Joined",
        description: senior
          ? `${userName} Called you`
          : `${userName} joined the call`,
        placement: "topLeft",
        maxCount: 5,
        onClick: () => {},
      });
      try {
        notificationAudio
          .play()
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    };

    notify(props.displayName, props.groupName, props.senior);
  }, []);

  return null;
}
const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  createJanusConnections: (groupid, lineOn, mute) => {
    dispatch(createJanusHandle(groupid, lineOn, mute));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Notification);

import { connect } from "react-redux";
import {
  showHandRaisePopUp,
  activateGroup,
} from "../../../Redux/Actions/Action";
import { showChatBoxAction, showRecorder } from "../../../Redux/Actions/Action";
import { getGroupMessageHistory } from "../../../Redux/Actions/firebaseActions";
import { CrossLogo } from "../../../assets";

const HandRaisedPopUp = (props) => {
  return (
    <div className="start_container">
      <div
        className="start_day"
        style={{ height: "max-content", paddingBottom: "25px" }}
      >
        <div
          style={{
            alignSelf: "flex-end",
            marginRight: "5px",
            marginTop: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CrossLogo
            style={{ height: "40px", cursor: "pointer" }}
            onClick={() => {
              props.closeHandRaise();
            }}
          />
        </div>
        <button
          className="start_btn"
          style={{ marginBottom: "10px" }}
          onClick={() => {
            if (!props.groupChats?.length) {
              props
                .getMessageHistory(props?.activeGroup?.groupid)
                .then(() => {})
                .catch((e) => {
                  console.log(e);
                });
            }
            props.showChatBoxAction(props?.activeGroup?.groupid);
          }}
        >
          Add Message
        </button>
        <button
          className="start_btn"
          onClick={() => {
            if (!props.groupChats?.length) {
              props
                .getMessageHistory(props?.activeGroup?.groupid)
                .then(() => {})
                .catch((e) => {
                  console.log(e);
                });
            }
            props.showRecorder();
          }}
        >
          Add Voice Note
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    activeGroup: state.activeGroup,
    groupChats: state.groupsChats[state.activeGroup.groupid],
  };
};
const mapDispatchToProps = (dispatch) => ({
  closeHandRaise: () => {
    dispatch(
      activateGroup({ groupName: "", members: [], groupid: "", seniors: {} })
    );
    dispatch(showHandRaisePopUp(false));
  },
  getMessageHistory: (groupid) => {
    return dispatch(getGroupMessageHistory(groupid));
  },
  showChatBoxAction: (groupid) => {
    dispatch(showChatBoxAction(groupid));
    dispatch(showHandRaisePopUp(false));
  },
  showRecorder: () => {
    dispatch(showRecorder(true));
    dispatch(showHandRaisePopUp(false));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(HandRaisedPopUp);

import React, { useState, useEffect } from "react";
import { Row, Col, Avatar, notification, Tooltip, message } from "antd";
import "./Group.css";
import { InfoCircleFilled } from "@ant-design/icons";

import {
  GroupLogo,
  OnlineLogo,
  SettingLogo,
  TalkLogo,
  soundLogo,
  chatLogo,
  LoaderLogo,
  CallLogo,
  LogoLogo,
  RightArrowLogo,
  AddLogo,
  RaiseHand,
  MicLogo,
} from "../../../assets/index.js";
import {
  setNewMsgInGroup,
  showChatBoxAction,
  showGroupSettings,
  showRecorder,
  showHandRaisePopUp,
  autoPlayRecording,
  showSeniorsConfirmationPopUp,
} from "../../../Redux/Actions/Action";
import { connect } from "react-redux";
import {
  joinJanusRoom,
  // janusRoomJoinning,
} from "../../../Redux/Actions/JanusClientActions.js";

import { trackEventActions } from "../../../Redux/Actions/firebaseActions";
import { CrossLogo } from "../../../assets/index.js";
import { activateGroup } from "../../../Redux/Actions/Action";
import {
  sendMessageAction,
  setLineOn,
  deleteGroup,
} from "../../../Redux/Actions/WsActions";
import { hash } from "../../../logic/common/Utils";
import { getGroupMessageHistory } from "../../../Redux/Actions/firebaseActions";
import Notification from "../Notification/Notification";
import { getLastReadRecording } from "../../../Redux/Actions/LocalStorageActions";
import CustomPopUp from "../CustomPopUp/CustomPopUp";
let online_status = {};

function Group(props) {
  // console.log(props);

  // const [online, setOnline] = useState(props.talking);
  // const [user, setUser] = useState(props.user);
  const [showUnseenPopUp, setShowUnseenPopUp] = useState(false);
  const [anotherPersonUid, setUid] = useState(null);
  const [janusRoomJoinning, setJanusRoomJoining] = useState(false);
  const [showJuniorOfflinePopup, setShowJuniorOffline] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [noMic, setNoMic] = useState(false);

  useEffect(() => {
    props.members.forEach((user) => {
      if (user.uid !== props.user.uid) {
        setUid(user.uid);
      }
    });
  }, []);

  const handleYes = () => {
    setShowUnseenPopUp(false);
    joinChatRoom(true);
  };
  const handleNo = async () => {
    setShowUnseenPopUp(false);
    if (!props.seniors[props.user.uid]) {
      await handleRaiseHand();
      return;
    }
    JoinRoom();
  };

  const talkStyle = {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    padding: "2px",
    border: "solid 3px #00bf19",
    outline: "2px solid  #00bf1921",
    outlineOffset: "3px",
    backgroundColor: "#f1a200",
    cursor: "pointer",
    fontSize: "25px",
  };
  const nonTalkStyle = {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    padding: "2px",
    border: "solid 3px  rgba(255,255,255,0)",
    outline: "2px solid  rgba(255,255,255,0)",
    outlineOffset: "3px",
    backgroundColor: "#f1a200",
    cursor: "pointer",
    fontSize: "25px",
    lineHeight: "2",
  };

  const onlineStyle = {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#00bf19",
    border: "4px solid #f2fcf3",
    position: "absolute",
    right: "10px",
  };
  const busyStyle = {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#d63131",
    border: "4px solid #fff0f0",
    position: "absolute",
    right: "10px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bolder",
    lineHeight: "3",
  };

  const colorList = [
    "#f56a90",
    "#7265e6",
    "#ffbf9f",
    "#00a2ae",
    "#f1a200",
    "#00a2ae",
    "#7265e6",
    "#f56a90",
    "#ffbf9f",
    "#ffbf9f",
    "#f1a200",
  ];

  const handleRaiseHand = async () => {
    if (props.plug && props.groupid) {
      // console.log(props);
      let payload = {};
      payload.groupid = props.groupid;
      payload.displayName = props?.user?.displayName;
      payload.type = "NUDGE";
      payload.message = "NUDGE";
      const seniorName = props.conversation
        ? props.members
            .map((obj) => {
              if (obj.email !== props.user.email) return obj.displayName;
            })
            .filter((o) => o)
        : props.groupName;
      notification.info({
        message: "Success",
        description: `You have successfully requested for a call back. ${seniorName} has been notified.`,
        placement: "topLeft",
        maxCount: 2,
        onClick: () => {},
      });
      await props.sendMessageAction(payload);
    }
  };

  const handleDeleteGroup = async () => {
    let payload = {};
    payload.groupid = props.groupid;
    setShowConfirmDelete(false);
    await props.deleteGroup(payload);
  };

  const checkIfStreamIsActive = () => {
    if (window.STREAM) {
      return window.STREAM.active;
    } else return false;
  };

  function getNewMicTrack() {
    return new Promise((res,rej)=>{
      navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(function (stream) {
        console.log("You let me use your mic!");
        window.STREAM = stream;
        const audioTrack = stream.getAudioTracks()[0];
        console.log(audioTrack);
        window.STREAM.oninactive = () => {
          console.log("Stream became inactive");
        };
        res();
       
      })
      .catch(function (err) {
        console.log("No mic for you!");
        setNoMic(true);
        rej()
      });
    })
  }

  // console.log(props);
  async function JoinRoom() {
    // setShowUnseenPopUp(false);
    if (props.plug) {
      if (!checkIfStreamIsActive()) {
        await getNewMicTrack()
      }

      if (janusRoomJoinning) {
        console.log("room joining process already in room", janusRoomJoinning);
        return;
      }
      setJanusRoomJoining(true);
      await props.joinJanusRoom(props.groupid);
      setJanusRoomJoining(false);
      if (!props.groupsChats?.length)
        props
          .getMessageHistory(props.groupid)
          .then(() => {})
          .catch((e) => {
            console.log(e);
          });
      props
        .setLineOn({ groupid: props.groupid })
        .then(() => {})
        .catch((e) => console.log(e));
    }
  }

  function joinChatRoom(hideRecorder) {
    // setShowUnseenPopUp(false);

    if (!hideRecorder) {
      props.showRecorder();
      if (!props.groupsChats?.length)
        props
          .getMessageHistory(props.groupid)
          .then(() => {})
          .catch((e) => {
            console.log(e);
          });
      return;
    } else if (hideRecorder) {
      if (!props.groupsChats?.length)
        props
          .getMessageHistory(props.groupid)
          .then(() => {})
          .catch((e) => {
            console.log(e);
          });
      props.autoPlayRecording();
    }
    props.showChatBoxAction(props.groupid);

    trackEventActions("chaton", {
      group: props.groupName,
      groupid: props.groupid,
    });
  }

  return (
    <Row
      style={{
        marginBottom: "30px",
        marginRight: "20px",
        // paddingBottom: "10px",
        // borderBottom: "1px solid #eee",
      }}
      className={`plugApp_group ${props.istemp ? "tempcall" : ""}`}
    >
      {showUnseenPopUp ? (
        <CustomPopUp
          message="You have new voice notes, do you want to play them?"
          handleNo={handleNo}
          handleYes={handleYes}
          closePopUp={() => {
            setShowUnseenPopUp(false);
          }}
        />
      ) : null}
      {noMic ? (
        <CustomPopUp
          message="Allow mic permission and refresh page"
          handleNo={null}
          handleYes={null}
          refreshBtn={true}
          color="#f1a200"
        />
      ) : null}
      {showConfirmDelete ? (
        <CustomPopUp
          message="Are you sure you want to delete this conversation?"
          handleNo={() => {
            setShowConfirmDelete(false);
          }}
          handleYes={async () => {
            console.log("Deleting group", props.groupid);
            await handleDeleteGroup();
          }}
          closePopUp={() => {
            setShowConfirmDelete(false);
          }}
        />
      ) : null}
      {showJuniorOfflinePopup ? (
        <div className="junior_offline_wrapper">
          <div className="junior_offline_container">
            <p>
              <span style={{ fontWeight: "bolder", marginRight: "5px" }}>
                {props.conversation
                  ? props.members
                      .map((obj) => {
                        if (obj.email !== props.user.email)
                          return obj.displayName;
                      })
                      .filter((o) => o)
                  : props.groupName}
              </span>
              is not available for a call right now. Would you like to send a
              voice note instead?
            </p>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}></div>
              <button
                className="start_btn"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  joinChatRoom();

                  setShowJuniorOffline(false);
                }}
              >
                Yes
              </button>
              <button
                className="start_btn"
                onClick={() => {
                  setShowJuniorOffline(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <Col span={24}>
        <Row align="middle" style={{ marginBottom: "10px" }}>
          <Col flex={1}>
            <Row align="middle">
              {props.plug && Object.keys(props.lineOnStatus).length ? (
                <span
                  style={{
                    height: "15px",
                    width: "15px",
                    backgroundColor: "#00bf19",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                ></span>
              ) : null}
              <h3
                className="group_heading"
                style={{
                  color:
                    props.plug && Object.keys(props.lineOnStatus).length
                      ? "#00bf19"
                      : "black",
                }}
              >
                {props.conversation
                  ? props.members.map((obj) => {
                      if (obj.email !== props.user.email)
                        return obj.displayName;
                    })
                  : props.groupName}
              </h3>
              <span style={{ display: "flex", marginLeft: "20px" }}>
                <span className="statusLogo">
                  <Tooltip
                    placement="bottom"
                    title={
                      <div>
                        <p style={{ color: "#121212", fontWeight: "bolder" }}>
                          Group Members
                        </p>
                        {props.members
                          .slice(0, 5)
                          .concat(
                            props.members.length > 5
                              ? [
                                  {
                                    displayName: `+${
                                      props.members.length - 5
                                    } more`,
                                  },
                                ]
                              : []
                          )
                          .map((obj, idx) => {
                            return (
                              <p
                                style={{ color: "#121212" }}
                                key={`list-tooptip-${idx}`}
                              >
                                {obj?.displayName ?? "@someone"}
                              </p>
                            );
                          })}
                      </div>
                    }
                    color={"#f7f7f7"}
                  >
                    <GroupLogo style={{ height: "22px", width: "22px" }} />
                  </Tooltip>
                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {props.members.length}
                  </span>
                </span>
                <span className="statusLogo">
                  <Tooltip
                    placement="bottom"
                    title={
                      <div>
                        <p style={{ color: "#121212", fontWeight: "bolder" }}>
                          Members Online
                        </p>
                        {props?.onlineInGroup?.map((uid, idx) => {
                          return (
                            <p
                              style={{ color: "#121212" }}
                              key={`list-tooptip-${idx}`}
                            >
                              {props.onlineStatus?.[uid]?.profile
                                ?.displayName === ""
                                ? props.onlineStatus?.[uid]?.profile?.email
                                : props.onlineStatus?.[uid]?.profile
                                    ?.displayName}
                            </p>
                          );
                        })}
                      </div>
                    }
                    color={"#f7f7f7"}
                  >
                    <OnlineLogo
                      style={{
                        height: "22px",
                        width: "22px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>

                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {props?.onlineInGroup?.length}
                  </span>
                </span>
                <span className="statusLogo" style={{ marginRight: "20px" }}>
                  <Tooltip
                    placement="bottom"
                    color={"#f7f7f7"}
                    title={
                      <div>
                        <p style={{ color: "#121212", fontWeight: "bolder" }}>
                          Members Talking
                        </p>
                        {Object?.keys(props.lineOnStatus).length
                          ? Object?.keys(props.lineOnStatus)?.map(
                              (uid, idx) => {
                                return (
                                  <p
                                    style={{ color: "#121212" }}
                                    key={`list-tooptip-${idx}`}
                                  >
                                    {props.onlineStatus?.[uid]?.profile
                                      ?.displayName ??
                                      props.onlineStatus?.[uid]?.profile?.email}
                                  </p>
                                );
                              }
                            )
                          : null}
                      </div>
                    }
                  >
                    <TalkLogo
                      style={{
                        height: "22px",
                        width: "22px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>

                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {Object?.keys(props.lineOnStatus)?.length}
                  </span>
                </span>
              </span>
              {/* <span
                style={{
                  color: props.userOnlineTime?.online ? "#f1a200" : "#aaa",
                  display: !props.conversation ? "none" : "block",
                  fontWeight: "bolder",
                }}
              >
                {props.userOnlineTime?.hour} {props.userOnlineTime?.minute}
              </span> */}
              <span
                style={{
                  color: "#03bf19",
                  display: props.istemp ? "block" : "none",
                  fontWeight: "bolder",
                }}
              >
                <InfoCircleFilled /> CONFERENCE CALL
              </span>
            </Row>
          </Col>
          <Col>
            <Row align="middle">
              {props.groupid ? (
                props.conversation ? (
                  <div className="group_setting">
                    <SettingLogo
                      style={{ cursor: "pointer" }}
                      className="groupSetting_logo"
                    />
                    <div className="group_setting_dropdown">
                      <p
                        onClick={() => {
                          setShowConfirmDelete(true);
                        }}
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="group_setting">
                    <SettingLogo
                      style={{ cursor: "pointer" }}
                      className="groupSetting_logo"
                    />
                    <div className="group_setting_dropdown">
                      <p
                        onClick={() => {
                          props.activateGroup({
                            groupName: props.groupName,
                            members: props.members,
                            groupid: props.groupid,
                          });
                          props.showSettings();
                        }}
                      >
                        Settings
                      </p>
                    </div>
                  </div>
                )
              ) : null}
            </Row>
          </Col>
        </Row>
        <Row style={{ justifyContent: "space-between" }} gutter={[2, 20]}>
          <Col
            md={16}
            xs={20}
            style={{
              backgroundColor:
                props.plug && Object.keys(props.lineOnStatus).length
                  ? "rgba(0, 191, 25, 0.05)"
                  : "#f7f7f7",

              borderRadius: "16px",
              marginLeft: "2px",
              border:
                props.plug && Object.keys(props.lineOnStatus).length
                  ? "solid 2px #00bf19"
                  : "none",
            }}
            className="group_user_container"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                width: "100%",

                overflowX: "auto",
                overflowY: "hidden",
              }}
              className="group_hide_scrollbar"
            >
              {props.plugON &&
                props.members.map((obj, index) => {
                  return (
                    <span key={`user-li-${index}`} className="user_wrapper">
                      {props.plugON &&
                      props.notification === obj?.uid &&
                      props.notification !== props.user.uid ? (
                        <Notification
                          displayName={obj?.displayName}
                          groupName={props.groupName}
                          groupid={props.groupid}
                          senior={(props.seniors || {})[obj.uid]}
                        />
                      ) : null}
                      <Tooltip
                        title={
                          props.plugON && props.lineOnStatus?.[obj?.uid]
                            ? "this person is talking in this group"
                            : obj.uid !== props.myUid && props.busyStatus
                            ? "this person is busy on another call "
                            : props.onlineStatus?.[obj?.uid]?.online
                            ? "this person is available on plug to talk"
                            : "this person is not available"
                        }
                        color={props.onlineStatus?.[obj?.uid]?.online?"#f1a200":"rgb(170,170,170)"}
                      >
                        {obj.photoURL ? (
                          <img
                            style={{
                              borderRadius: "50%",
                              padding: "2px",
                              border:
                                props.plug && props.lineOnStatus?.[obj?.uid]
                                  ? "solid 2px #00bf19"
                                  : "solid 2px  rgba(255,255,255,0)",
                              outline:
                                props.plug && props.lineOnStatus?.[obj?.uid]
                                  ? "1px solid  #00bf1921"
                                  : "1px solid  rgba(255,255,255,0)",
                              outlineOffset: "3px",

                              cursor: "pointer",
                              fontSize: "25px",
                            }}
                            className="group_user_img"
                            src={obj.photoURL}
                          />
                        ) : (
                          <Avatar
                            style={{
                              borderRadius: "50%",
                              padding: "2px",
                              backgroundClip: "content-box",
                              border:
                                props.plug && props?.lineOnStatus?.[obj?.uid]
                                  ? "solid 2px #00bf19"
                                  : "solid 2px  rgba(255,255,255,0)",
                              outline:
                                props.plug && props?.lineOnStatus?.[obj?.uid]
                                  ? "1px solid  #00bf1921"
                                  : "1px solid  rgba(255,255,255,0)",
                              outlineOffset: "3px",
                              backgroundColor:
                                colorList[
                                  hash(obj?.displayName ?? "@someone") % 11
                                ],

                              cursor: "pointer",
                              fontSize: "25px",
                            }}
                            className="group_user_avatar"
                          >
                            {obj?.displayName?.[0]?.toUpperCase() ?? "@"}
                          </Avatar>
                        )}
                      </Tooltip>

                      {props.plugON &&
                      !props?.lineOnStatus?.[obj?.uid] &&
                      props.onlineStatus?.[obj?.uid]?.online ? (
                        obj.uid !== props.myUid && props.busyStatus ? (
                          <span style={busyStyle} key={index}>
                            -
                          </span>
                        ) : (
                          <span style={onlineStyle} key={index}></span>
                        )
                      ) : null}

                      <span className="user_name">
                        {props.user.uid === obj.uid
                          ? "you"
                          : obj?.displayName ?? "@someone"}
                      </span>
                    </span>
                  );
                })}

              {/* {props.members.length > 5 ? (
                <span style={{ fontWeight: "bold" }}>
                  {props.members.length - 6} more...
                </span>
              ) : null} */}
            </div>
          </Col>
          <Col md={1} xs={0}></Col>
          <Tooltip
            placement="bottom"
            title={
              props.plugON
                ? props.newMessageInGroup
                  ? `${props.members
                      .map((obj) => {
                        if (obj.uid !== props.user.uid) return obj.displayName;
                      })
                      .filter((o) => o)} ${
                      props.newMessageInGroup == "NUDGE"
                        ? "has requested a call"
                        : props.newMessageInGroup == "MEDIA"
                        ? "has sent you a voice note"
                        : "has sent you a message."
                    }`
                  : props.seniors[props.user.uid] === undefined &&
                    (props.seniors[anotherPersonUid] == undefined ||
                      props.seniors[anotherPersonUid] == false)
                  ? "click on it to add member"
                  : props.seniors[props.user.uid] ||
                    props.seniors[props.user.uid] ===
                      props.seniors[anotherPersonUid]
                  ? "click on it and start talking to your teammates"
                  : "click on it to send a request to call back"
                : null
            }
            color={"#f1a200"}
            style={{ borderRadius: "10px" }}
          >
            <Col
              md={2}
              xs={18}
              className={
                props.plug ? "hoverEffect sound_container" : "sound_container"
              }
              style={{
                backgroundColor: props.istemp
                  ? "#f1a200"
                  : !props.groupid ||
                    (props.seniors[props.user.uid] === undefined &&
                      (props.seniors[anotherPersonUid] == undefined ||
                        props.seniors[anotherPersonUid] == false))
                  ? "#eee"
                  : !props.onlineStatus?.[anotherPersonUid]?.online
                  ? // !props.istemp &&
                    // (!props.seniors || !props.seniors[props.user.uid]) &&
                    // !(Object.keys(props.lineOnStatus).length >= 1)
                    "#aaa"
                  : "#f1a200",
                opacity: "1",
                disable: props.plugON ? false : true,
                cursor: "pointer",
              }}
              onClick={async () => {
                if (!props.plugON) return;
                if (props.virtualuid) {
                  props.activateGroup({
                    groupName: props.groupName,
                    members: props.members,
                    groupid: "virtualgrp",
                  });
                  let anotherPerson = props.members.filter(
                    (user) => user.uid != props.user.uid
                  )[0];
                  props.showSeniorPopUp(
                    anotherPerson?.displayName ?? anotherPerson?.email
                  );
                } else {
                  props.activateGroup({
                    groupName: props.groupName,
                    members: props.members,
                    groupid: props.groupid,
                    seniors: props.seniors || {},
                  });
                  if (props.istemp) {
                    JoinRoom();
                  } else if (Object.keys(props.lineOnStatus).length >= 1) {
                    JoinRoom();
                  } else {
                    if (props?.seniors) {
                      // check if user is senior or not.
                      if (
                        props.seniors[props.user.uid] === undefined &&
                        (props.seniors[anotherPersonUid] == false ||
                          props.seniors[anotherPersonUid] == undefined)
                      ) {
                        let anotherPerson = props.members.filter(
                          (user) => user.uid != props.user.uid
                        )[0];

                        props.showSeniorPopUp(
                          anotherPerson?.displayName ?? anotherPerson?.email
                        );
                      } else if (
                        props.seniors[props.user.uid] === true ||
                        props.seniors[props.user.uid] ===
                          props.seniors[anotherPersonUid]
                      ) {
                        // normally open the line on pop up .
                        // before lineon if chat box have unread voice notes prompt user to read the
                        // voice note first and then click on talk;
                        // console.log(props);
                        if (!props.onlineStatus?.[anotherPersonUid]?.online) {
                          setShowJuniorOffline(true);

                          return;
                        }
                        // console.log(props.lastRecordingTimestamp);
                        let timestamp = null;
                        if (!props.groupsChats?.length) {
                          // props.roomJoinning(props.groupid);
                          setJanusRoomJoining(true);
                          timestamp = await props.getMessageHistory(
                            props.groupid
                          );
                          // props.roomJoinning(null);
                          setJanusRoomJoining(false);
                          // console.log("this is timestamp", timestamp);
                        }
                        if (!timestamp)
                          timestamp = props.lastRecordingTimestamp;
                          // console.log(props.lastRecordingTimestamp)
                     
                        // console.log(timestamp,timestamp>Date.now() - 2 * 24 * 60 * 60 * 1000);

                        if (
                          timestamp &&
                          timestamp > Date.now() - 2 * 24 * 60 * 60 * 1000
                        ) {
                          let lastReadRecording =
                            getLastReadRecording(props.user.uid)?.[
                              props.groupid
                            ] ?? 1;
                          // console.log(timestamp > lastReadRecording);
                          if (timestamp > lastReadRecording) {
                            // show chat box;
                            message.info("play unread voice notes ");
                            setShowUnseenPopUp(true);
                            // joinChatRoom(true);
                          } else {
                            JoinRoom();
                          }
                        } else {
                          JoinRoom();

                          // joinChatRoom(true);
                        }
                      } else if (!props.seniors[props.user.uid]) {
                        // open chat box with audio open.
                        // props.showHandRaisePopUp(); // show popup that ask question to whether send voice note or message.

                        let timestamp = null;
                        if (!props.groupsChats?.length) {
                          timestamp = await props.getMessageHistory(
                            props.groupid
                          );
                        }
                        if (!timestamp)
                          timestamp = props.lastRecordingTimestamp;
                          // console.log(props.lastRecordingTimestamp)
                        // console.log(timestamp,timestamp>Date.now() - 2 * 24 * 60 * 60 * 1000);
                        if (
                          timestamp &&
                          timestamp > Date.now() - 2 * 24 * 60 * 60 * 1000
                        ) {
                          let lastReadRecording =
                            getLastReadRecording(props.user.uid)?.[
                              props.groupid
                            ] ?? 1;
    // console.log(timestamp,lastReadRecording)
                          if (timestamp > lastReadRecording) {
                            // show chat box;
                            message.info("play unread voice notes ");
                            setShowUnseenPopUp(true);
                            // joinChatRoom(true);
                          } else {
                            await handleRaiseHand();
                          }
                        } else {
                          await handleRaiseHand();
                        }
                      }
                    } else {
                      // show the pop up that ask if the user is senior or not.
                      let anotherPerson = props.members.filter(
                        (user) => user.uid != props.user.uid
                      )[0];

                      props.showSeniorPopUp(
                        anotherPerson?.displayName ?? anotherPerson?.email
                      );
                    }
                  }
                }
              }}
            >
              {janusRoomJoinning ? (
                <LoaderLogo
                  className={
                    !props.istemp &&
                    (!props.seniors ||
                      props.seniors[props.user.uid] === undefined)
                      ? "spinner"
                      : "call_spinner spinner"
                  }
                />
              ) : (
                <>
                  {/* <img
                    src={soundLogo}
                    className="soundLogo"
                    draggable="false"
                  /> */}
                  {props.groupid ? (
                    props.seniors ? (
                      !props.istemp &&
                      props.seniors[props.user.uid] === undefined &&
                      (props.seniors[anotherPersonUid] == undefined ||
                        props.seniors[anotherPersonUid] == false) ? (
                        // <div className="call_raise_container">
                        //   <CallLogo
                        //     style={{
                        //       width: "30px",
                        //       height: "30px",

                        //       marginRight: "20px",
                        //     }}
                        //   />
                        //   <div className="logo_divider"></div>

                        //   <RaiseHand
                        //     className="chatLogo"
                        //     style={{
                        //       marginLeft: "20px",
                        //       width: "25px",
                        //       height: "25px",
                        //     }}
                        //   />
                        // </div>
                        <>
                          {/* <CallLogo style={{ width: "40px", height: "40px" }} /> */}
                          <AddLogo style={{ width: "40px", height: "40px" }} />
                          <span
                            className="soundLogoText"
                            style={{ marginTop: "10px", color: "black" }}
                          >
                            Add
                          </span>
                        </>
                      ) : props.seniors[props.user.uid] ||
                        props.seniors[props.user.uid] ===
                          props.seniors[anotherPersonUid] ? (
                        props.onlineStatus?.[anotherPersonUid]?.online ? (
                          <>
                            <CallLogo
                              style={{ width: "40px", height: "40px" }}
                            />
                            <span className="soundLogoText">Call</span>
                          </>
                        ) : (
                          <>
                            <CallLogo
                              style={{ width: "40px", height: "40px" }}
                            />

                            <span className="soundLogoText">Call</span>
                          </>
                        )
                      ) : Object.keys(props.lineOnStatus).length >= 1 ? (
                        <>
                          <CallLogo style={{ width: "40px", height: "40px" }} />
                          <span className="soundLogoText">Call</span>
                        </>
                      ) : (
                        <>
                          {/* <img
                            src={RaiseHand}
                            alt="handRaise"
                            className="chatLogo"
                          /> */}
                          <RaiseHand className="chatLogo" />

                          <span
                            className="soundLogoText"
                            style={{ marginTop: "10px", color: "white" }}
                          >
                            Raise
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        <img
                          src={soundLogo}
                          draggable="false"
                          className="chatLogo"
                        />
                        <span
                          className="soundLogoText"
                          style={{ marginTop: "10px", color: "black" }}
                        >
                          Talk
                        </span>
                      </>
                    )
                  ) : (
                    <>
                      {/* <CallLogo style={{ width: "40px", height: "40px" }} /> */}
                      <AddLogo style={{ width: "40px", height: "40px" }} />
                      <span
                        className="soundLogoText"
                        style={{ marginTop: "10px", color: "black" }}
                      >
                        Add
                      </span>
                    </>
                  )}
                  {props.newMessageInGroup ? (
                    <span
                      style={{
                        position: "absolute",
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                        backgroundColor: "#FF4433",
                        top: "-10px",
                        right: "-10px",
                      }}
                    ></span>
                  ) : null}
                  {/* {props?.seniors?.[props.user.uid] ?? true ? (
                    <>
                      <CallLogo style={{ width: "40px", height: "40px" }} />
                      <span className="soundLogoText">Call</span>
                    </>
                  ) : (
                    <>
                      <LogoLogo style={{ width: "40px", height: "40px" }} />
                      <span className="soundLogoText">Voice Note</span>
                    </>
                  )} */}
                </>
              )}
            </Col>
          </Tooltip>
          <Col
            md={2}
            xs={4}
            className={
              props.plug ? "hoverEffect sound_container" : "sound_container"
            }
            style={{
              opacity: props.plug ? "1" : "0.5",
              marginRight: "0px",
              marginLeft: "-5%",
              cursor: "pointer",
              maxWidth: "100px",
              transform: "scale(0.6)",
            }}
            onClick={() => {
              if (props.plug) {
                props.activateGroup({
                  groupName: props.groupName,
                  members: props.members,
                  groupid: props.groupid,
                });
                if (!props.groupsChats?.length)
                  props
                    .getMessageHistory(props.groupid)
                    .then(() => {})
                    .catch((e) => {
                      console.log(e);
                    });
                props.showChatBoxAction(props.groupid);
                trackEventActions("chaton", {
                  group: props.groupName,
                  groupid: props.groupid,
                });
              }
            }}
          >
            {/* <img src={chatLogo} draggable="false" className="chatLogo" />
            <span className="chatLogoText">Chat</span> */}
            <RightArrowLogo />
          </Col>
          {/* <Col
            md={2}
            xs={4}
            className={
              props.plug ? "hoverEffect sound_container" : "sound_container"
            }
            style={{
              opacity: props.plug ? "1" : "0.5",
              marginRight: "-2%",
              marginLeft: "-5%",
              cursor: "pointer",
              maxWidth: "100px",
              transform: "scale(0.6)",
              visibility:
                (props.seniors && props.seniors[props.user.uid] === true) ||
                !props.groupid
                  ? "hidden"
                  : "",
            }}
            onClick={handleRaiseHand}
          >
            <img src={chatLogo} draggable="false" className="chatLogo" />
            <span className="chatLogoText">Chat</span>
            <Tooltip
              placement="bottom"
              title={props.plugON ? "click here to request a call back" : null}
              color={"#f1a200"}
              style={{ borderRadius: "10px" }}
            >
              <img
                src={RaiseHand}
                className={"hoverEffect"}
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "25%",
                  background: "#e6f7e5",
                  borderRadius: "20%",
                }}
                draggable="false"
              ></img>
            </Tooltip>
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
}
const mapStateToProps = (state, ownProps) => {
  let anotherUserUid = null;
  // console.log(ownProps, state.lineOnStatus?.[ownProps.groupid]);
  ownProps.members.forEach((user) => {
    if (user.uid !== ownProps.myUid) {
      anotherUserUid = user.uid;
    }
    online_status[user.uid] = state.onlineStatus?.[user.uid];
  });
  // console.log(state);

  return {
    plugON: state.plugON,
    // janusRoomJoinning: false,
    groupsChats: state.groupsChats?.[ownProps.groupid],
    lineOnStatus: state.lineOnStatus?.[ownProps.groupid] ?? {},
    onlineInGroup: state.onlineInGroup?.[ownProps.groupid],
    onlineStatus: online_status,
    notification: state.notification?.[ownProps.groupid],
    busyStatus: state.busyStatus?.[anotherUserUid],
    lastRecordingTimestamp: state.lastRecordingTimestamp?.[ownProps.groupid],
    newMessageInGroup: state.newMessageInGroup?.[ownProps.groupid],
    user: state.user,
    // userOnlineTime: state.userOnlineTime?.[anotherUserUid],
  };
};

const mapDispatchToProps = (dispatch) => ({
  showSettings: () => dispatch(showGroupSettings(true)),
  joinJanusRoom: (groupid) => dispatch(joinJanusRoom(groupid)),
  activateGroup: (payload) => {
    dispatch(activateGroup(payload));
  },
  // roomJoinning: (groupid) => {
  //   dispatch(janusRoomJoinning(groupid));
  // },
  setLineOn: (payload) => dispatch(setLineOn(payload)),

  showChatBoxAction: (groupid) => {
    dispatch(setNewMsgInGroup({ [groupid]: false }));
    dispatch(showChatBoxAction(groupid));
  },
  getMessageHistory: (groupid) => {
    return dispatch(getGroupMessageHistory(groupid));
  },
  deleteGroup: async (payload) => dispatch(await deleteGroup(payload)),
  showSeniorPopUp: (nameOrEmail) =>
    dispatch(showSeniorsConfirmationPopUp(nameOrEmail)),
  showRecorder: () => {
    dispatch(showRecorder(true));
  },
  showHandRaisePopUp: () => {
    dispatch(showHandRaisePopUp(true));
  },
  autoPlayRecording: () => {
    dispatch(autoPlayRecording(true));
  },
  sendMessageAction: (payload) => {
    return dispatch(sendMessageAction(payload));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Group));

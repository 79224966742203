import React, { useEffect } from "react";
import { useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { CrossLogo, MicLogo, ChatSend } from "../../../assets";
import { connect } from "react-redux";
import {
  uploadFileToCloud,
  getDownloadableUrl,
} from "../../../Redux/Actions/firebaseActions";
import { message, Spin } from "antd";
import { sendMessageAction } from "../../../Redux/Actions/WsActions";
import "./RecordView.css";
function RecordView(props) {
  const [start, setStart] = useState(false);
  const [send, setSend] = useState(false);
  const [seconds, setSeconds] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    mediaRecorderOptions,
  } = useReactMediaRecorder({
    audio: true,
    onStop: (blobUrl, resulted_blob) => {
      // console.log(resulted_blob, blobUrl);
      handleSendMedia(resulted_blob);
    },
  });

  const handleSendMedia = async (blob) => {
    if (blob.size > 52428800) {
      message.info("audio size more than 50mb");
    }
    let payload = {};
    payload.groupid = props.activeGroup.groupid;
    payload.displayName = props?.user?.displayName;
    try {
      setLoading(true);
      payload.type = "AUDIO";
      const Url = await uploadFileToCloud(blob, "wav");

      payload.url = Url;
      payload.size = blob.size;
      console.log(payload);
      await props.sendMessageAction(payload);
      setLoading(false);

      props.closeRecordPopUp();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    startRecording();
    setStart(true);
  }, []);

  useEffect(() => {
    let interval;

    if (start) {
      interval = setInterval(() => {
        let count = counter + 1;
        let min_ = Math.floor(count / 60);
        let sec_ = count % 60;

        min_ = String(min_).length == 1 ? `0${min_}` : String(min_);
        sec_ = String(sec_).length == 1 ? `0${sec_}` : String(sec_);
        setCounter(count);
        setMinutes(min_);
        setSeconds(sec_);
      }, 1000);
    }

    if (send) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [start, counter, send]);

  return (
    <React.Fragment>
      {props.isAutoRecording ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#f1a200",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              // marginLeft: "-10px",
              marginBottom: "10px",
            }}
          >
            <MicLogo style={{ height: "30px", width: "30px" }} />
          </span>
          <span
            style={{
              fontWeight: "bolder",
              fontFamily: "Inter",
              fontSize: "16px",
            }}
          >
            {props?.user?.displayName}
          </span>
          <div style={{ marginBottom: "10px" }}>
            <span style={{ fontWeight: "bolder", fontSize: "16px" }}>
              {minutes}
            </span>
            <span style={{ fontWeight: "bolder", fontSize: "16px" }}>:</span>
            <span style={{ fontWeight: "bolder", fontSize: "16px" }}>
              {seconds}
            </span>
          </div>

          {send ? (
            <button
              style={{
                border: "none",
                outline: "none",
                color: "black",
                backgroundColor: "#eee",
                padding: "7px 25px",
                // marginTop: "10px",
                marginBottom: "30px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              // onClick={() => {
              //   stopRecording();
              //   setSend(true);
              // }}
            >
              Sending Voice Note
            </button>
          ) : (
            <button
              style={{
                border: "none",
                outline: "none",
                color: "white",
                backgroundColor: "#f1a200",
                padding: "7px 25px",
                // marginTop: "10px",
                marginBottom: "30px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                stopRecording();
                setSend(true);
              }}
            >
              Send Voice Note
            </button>
          )}
          {loading ? <Spin /> : null}
        </div>
      ) : (
        <div
          // className={
          //   props.lineOnChat
          //     ? "fileIcon audio_record lineOn_fileIcon"
          //     : "fileIcon audio_record"
          // }
          className="record_view"
        >
          <span
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#f1a200",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              marginLeft: "-10px",
            }}
          >
            <MicLogo style={{ height: "30px", width: "30px" }} />
          </span>

          {/* <CrossLogo
        style={{
          // alignSelf: "flex-end",
          marginRight: "10px",
          cursor: "pointer",
          display: "block",
          position: "absolute",
          right: "0px",
          top: "0px",
        }}
        onClick={() => {
          startRecording();
          props.closeRecordPopUp();
        }}
      /> */}
          <div style={{ marginLeft: "10px" }}>
            <span style={{ fontWeight: "bolder", fontSize: "16px" }}>
              {minutes}
            </span>
            <span style={{ fontWeight: "bolder", fontSize: "16px" }}>:</span>
            <span style={{ fontWeight: "bolder", fontSize: "16px" }}>
              {seconds}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: props.lineOnChat ? "column" : "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {start ? (
          // send ? (
          <div>
            <button
              onClick={() => {
                stopRecording();
                setSend(true);
                // console.log(mediaRecorderOptions);
              }}
              className="record_btn"
            >
              Send Recording
            </button>
          </div>
        ) : (
          <button
            onClick={() => {
              startRecording();

              setStart(true);
            }}
            className="record_btn"
          >
            Start Recording
          </button>
        )} */}
            {loading ? <Spin /> : null}
          </div>
          {send ? (
            <span
              style={{
                color: "#f1a200",
                position: "absolute",
                bottom: "8px",
                right: "50px",
                cursor: "pointer",
                fontFamily: "Inter",
                fontWeight: "normal",
              }}
            >
              Sending Voice Note
            </span>
          ) : (
            <span
              style={{
                color: "#f1a200",
                position: "absolute",
                bottom: "8px",
                right: "50px",
                cursor: "pointer",
                fontFamily: "Inter",
                fontWeight: "normal",
              }}
              onClick={() => {
                stopRecording();
                setSend(true);
              }}
            >
              Send Voice Note
            </span>
          )}

          <ChatSend
            onClick={() => {
              stopRecording();
              setSend(true);
            }}
            style={{
              color: "#f1a200",
              position: "absolute",
              bottom: "8px",
              right: "10px",
              cursor: "pointer",
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  sendMessageAction: (payload) => {
    return dispatch(sendMessageAction(payload));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RecordView);

import React, { useState } from "react";

import { connect } from "react-redux";
import {
  showSeniorsConfirmationPopUp,
  showRecorder,
  setNewMsgInGroup,
  showChatBoxAction,
} from "../../../Redux/Actions/Action";
import { CrossLogo } from "../../../assets";
import {
  updateGroup,
  setLineOn,
  createGroup,
} from "../../../Redux/Actions/WsActions";
import { activateGroup } from "../../../Redux/Actions/Action";
import {
  trackEventActions,
  getGroupMessageHistory,
} from "../../../Redux/Actions/firebaseActions";
import { joinJanusRoom } from "../../../Redux/Actions/JanusClientActions.js";

import "./ConfirmationPopUp.css";

function ConfirmationPopUp(props) {
  // join chat room after updating group and if user is not senior
  const [anotherQues, setAnotherQues] = useState(false);
  function joinChatRoom(groupid, groupName) {
    console.log("chat room");
    if (!props.groupsChats?.[groupid]?.length)
      props
        .getMessageHistory(groupid)
        .then(() => {})
        .catch((e) => {
          console.log(e);
        });
    props.showRecorder();
    // props.showChatBoxAction(groupid);

    trackEventActions("chaton", {
      group: groupName,
      groupid: groupid,
    });
  }

  // join janus room after updating group and if user is senior

  async function JoinRoom(groupid) {
    console.log("janus room ");

    if (props.janusRoomJoinning) {
      console.log(
        "room joining process already in room",
        props.janusRoomJoinning
      );
      return;
    }

    await props.joinJanusRoom(groupid);
    if (!props.groupsChats?.[groupid]?.length)
      props
        .getMessageHistory(groupid)
        .then(() => {})
        .catch((e) => {
          console.log(e);
        });
    props
      .setLineOn({ groupid: groupid })
      .then(() => {})
      .catch((e) => console.log(e));
  }

  const handleIsSenior = async (isSenior) => {
    let seniors = props?.activeGroup?.seniors ?? {};
    // console.log(seniors);

    let anotherPerson = props.activeGroup.members.filter(
      (user) => user.uid !== props.user.uid
    )[0];

    // if user is senior can put another as false
    if (isSenior == true) {
      seniors[props.user.uid] = true;
      seniors[anotherPerson.uid] = false;
    } else if (isSenior == false) {
      // not senior
      seniors[anotherPerson.uid] = true;
      seniors[props.user.uid] = false;
    } else {
      seniors[props.user.uid] = false;
    }
    // console.log(seniors);
    // new payload to update group
    let payload = {};
    payload.name = props.activeGroup.groupName;
    payload.groupid = props.activeGroup.groupid;
    payload.members = props.activeGroup.members.map((user) => {
      return {
        email: user.email,
        name: user?.name ?? user?.displayName,
        uid: user.uid,
      };
    });
    payload.seniors = seniors;
    payload.conversation = payload.members.length == 2;
    // console.log(payload);
    if (props.activeGroup.groupid === "virtualgrp") {
      await props.createGroup(payload);
    } else {
      await props.updateGroup(payload);
      if (
        (isSenior || isSenior === seniors[anotherPerson.uid]) &&
        props.onlineStatus?.[anotherPerson.uid]?.online
      )
        JoinRoom(props.activeGroup.groupid, props.activeGroup.groupName);
      else joinChatRoom(props.activeGroup.groupid);
    }

    props.closeConfirmationPopUp();
  };

  return (
    <div className="confirmation_container">
      <div className="confirmation_box">
        <CrossLogo
          style={{ alignSelf: "flex-end", cursor: "pointer" }}
          onClick={() => {
            props.closeConfirmationPopUp();
          }}
        />
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bolder",
            fontFamily: "Inter",
            alignSelf: "flex-start",
            marginLeft: "25px",
            marginBottom: "px",
          }}
        >
          {anotherQues
            ? `Do you directly report to ${props.showSeniorsConfirmation}`
            : `Does ${props.showSeniorsConfirmation} directly report to you?`}
        </p>
        <div style={{ alignSelf: "flex-end", marginBottom: "10px" }}>
          <button
            className="confirmation_btn "
            onClick={() => {
              if (anotherQues) {
                handleIsSenior(null);
              } else {
                setAnotherQues(true);
              }
            }}
          >
            No
          </button>
          <button
            className="confirmation_btn "
            onClick={() => {
              if (anotherQues) {
                handleIsSenior(false);
              } else {
                handleIsSenior(true);
              }
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  closeConfirmationPopUp: () => {
    dispatch(showSeniorsConfirmationPopUp(false));
  },
  updateGroup: (payload) => dispatch(updateGroup(payload)),
  createGroup: (payload) => {
    dispatch(createGroup(payload));
  },
  joinJanusRoom: (groupid) => dispatch(joinJanusRoom(groupid)),
  setLineOn: (payload) => dispatch(setLineOn(payload)),
  getMessageHistory: (groupid) => {
    return dispatch(getGroupMessageHistory(groupid));
  },
  showRecorder: () => {
    dispatch(showRecorder(true));
  },
  showChatBoxAction: (groupid) => {
    dispatch(setNewMsgInGroup({ [groupid]: false }));
    dispatch(showChatBoxAction(groupid));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPopUp);

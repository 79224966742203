import JanusClient from "../../logic/modules/JanusClient/JanusClient.js";
import {
  showLineOnPopUp,
  activateGroup,
  setUserLineOnInGroup,
} from "./Action.js";
const JanusClientInstance = new JanusClient();

export const janusRoomJoinning = (payload) => {
  return {
    type: "JANUS_ROOM_JOINED",
    payload: payload,
  };
};

const janusConnected = (payload) => {
  return {
    type: "JANUS_CONNECTED",
    payload: payload,
  };
};

export const initializeJanusClient = () => {
  return async (dispatch, getState) => {
    let result = await JanusClientInstance.init(getState().user);
    console.log(result);
    dispatch(janusConnected(true));
    return result;
  };
};

export const joinJanusRoom = (groupid) => {
  return async (dispatch, getState) => {
    try {
      console.log("room joining");
      // dispatch(janusRoomJoinning(groupid));
      // setTimeout(() => {

      // }, 1000 + Math.random() * 500);
      await JanusClientInstance.join(groupid, true);
      dispatch(showLineOnPopUp(groupid));
      dispatch(setUserLineOnInGroup(groupid));
      // dispatch(janusRoomJoinning(null));
      // console.log(getState());
    } catch (e) {
      console.log(e);
    }
  };
};

export const createJanusHandle = (groupid, lineon, mute) => {
  return async (dispatch, getState) => {
    console.log("room joining", mute);
    await JanusClientInstance.join(groupid, lineon, mute);
  };
};

export const disconnectJanus = (destroy) => {
  return async (dispatch, getState) => {
    await JanusClientInstance.reset();
    if (destroy) await JanusClientInstance.destroy();
    console.log("janus client instaces deleted");
  };
};

export const lineOffAction = (groupid, close) => {
  return async (dispatch) => {
    try {
      await JanusClientInstance.forcelineoff(groupid);
      if (close) {
        dispatch(showLineOnPopUp(false));

        dispatch(activateGroup({ groupName: "", members: [], groupid: "" }));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const leaveJanusRoom = () => {
  return async (dispatch) => {
    await JanusClientInstance.leaveRoom();

    dispatch(showLineOnPopUp(false));
  };
};

import React from "react";
import { useEffect } from "react";
import { getDownloadableUrl } from "../../../Redux/Actions/firebaseActions";
import { useState } from "react";
import "./ImagePreview.css";
import { CrossLogo, download } from "../../../assets";

const ImagePreview = ({ path, filename, fileext }) => {
  const [url, setUrl] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getDownloadableUrl(path)
      .then((url) => {
        // console.log(url);
        setUrl(url);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDownloadImg = async () => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename + "." + fileext);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  return (
    <div className="imagePreview_container">
      {url ? <img src={url} className="imagePreview" /> : null}
      <div
        className="hoverMsg"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <div className="msg_container">click on Image to Preview</div>
      </div>
      {showModal ? (
        <div className="img_modal">
          <div className="cross_logo">
            <img
              src={download}
              onClick={() => {
                handleDownloadImg();
              }}
            />
            <CrossLogo
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>
          <img src={url} className="img_modal_img" />
        </div>
      ) : null}
    </div>
  );
};
export default ImagePreview;

import { ReactComponent as Cross } from "./images/cross.svg";
import { ReactComponent as Arrow } from "./images/arr.svg";
import { ReactComponent as Cancel } from "./images/cancel.svg";
import { ReactComponent as Cancel_01 } from "./images/cancel_01.svg";
import { ReactComponent as Group } from "./images/group-2.svg";
import { ReactComponent as Online } from "./images/group-3.svg";
import { ReactComponent as Setting } from "./images/group-25.svg";
import { ReactComponent as Talk } from "./images/group-5.svg";
import { ReactComponent as Send } from "./images/send.svg";
import { ReactComponent as ThreeDots } from "./images/threeDots.svg";
import { ReactComponent as Add } from "./images/group-13-copy.svg";
import { ReactComponent as Loader } from "./images/loader.svg";
import { ReactComponent as Mic } from "./images/mic-black-24-dp-2-copy.svg";
import { ReactComponent as Upload } from "./images/upload-black-24-dp.svg";
import { ReactComponent as Type } from "./images/group-20.svg";
import { ReactComponent as Call } from "./images/group-21.svg";
import { ReactComponent as RightArrow } from "./images/right_arrow.svg";
import { ReactComponent as Expand } from "./images/open-in-full-black-24-dp.svg";
import { ReactComponent as Collapse } from "./images/close-fullscreen-black-24-dp.svg";
import { ReactComponent as Clock } from "./images/clock.svg";
import { ReactComponent as Raise } from "./images/hand.svg";

import sound from "./images/objects-tools-speaker@3x.png";
import chat from "./images/objects-sundry-bubble@3x.png";
import danger from "./images/danger.png"
import Girl from "./images/group-29@2x.png";
import Boy from "./images/group-30@3x.png";
import plug from "./images/group-28-copy-2@3x.png";
import copy from "./images/group-4@3x.png";
import windowLogo from "./images/icons-8-windows-8-100@3x.png";
import macLogo from "./images/icons-8-apple-logo-100@3x.png";
import linuxLogo from "./images/icons-8-linux-100@3x.png";
import search from "./images/objects-education-lup-3@3x.png";
import cube from "./images/objects-toys-cube-2@3x.png";
import raisePng from "./images/raisehand@2x.png";
import downloadPng from "./images/download.png"
import missedCallPng from "./images/missedCall.png"

export const soundLogo = sound;
export const chatLogo = chat;
export const loginGirl = Girl;
export const loginBoy = Boy;
export const plugLogo = plug;
export const copyRight = copy;
export const windows = windowLogo;
export const macOs = macLogo;
export const linux = linuxLogo;
export const RightArrowLogo = RightArrow;
export const searchLogo = search;
export const cubeLogo = cube;
export const CrossLogo = Cross;
export const ArrowLogo = Arrow;
export const CancelLogo = Cancel;
export const CancelLogo_01 = Cancel_01;
export const CallLogo = Call;
export const GroupLogo = Group;
export const OnlineLogo = Online;
export const SettingLogo = Setting;
export const TalkLogo = Talk;
export const ChatSend = Send;
export const Dots = ThreeDots;
export const AddLogo = Add;
export const LoaderLogo = Loader;
export const MicLogo = Mic;
export const UploadLogo = Upload;
export const TypeLogo = Type;
export const ExpandLogo = Expand;
export const CollapseLogo = Collapse;
export const RaiseHand = Raise;
export const ClockLogo = Clock;
export const RaiseHandPng = raisePng;
export const AlertLogo=danger;
export const download=downloadPng
export const missedCall= missedCallPng;
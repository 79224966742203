import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./juniorsStats.css";
import { getLoginActivity } from "../../../Redux/Actions/WsActions";
import {
  CallLogo,
  ClockLogo,
  LoaderLogo,
  RaiseHand,
  RaiseHandPng,
  CrossLogo,
} from "../../../assets";
import { Tooltip } from "antd";
import { CSVLink, CSVDownload } from "react-csv";
import { checkIsSameDay } from "../../../logic/common/Utils";

const headers = [
  { label: "Name", key: "displayName" },
  { label: "Email", key: "email" },
  { label: "Line On", key: "lineOnCount" },
  { label: "Hand Raise", key: "nudgeCount" },
  { label: "Time Spent", key: "timeSpent" },
];

const getDateYYYYMMDD = (time) => {
  let newDate = null;
  if (time) newDate = new Date(time);
  else newDate = new Date();
  let year = newDate.getUTCFullYear();
  let month = newDate.getUTCMonth() + 1;
  let date = newDate.getUTCDate();
  if (month < 10) month = "0" + month;
  if (date < 10) date = "0" + date;

  return `${year}-${month}-${date}`;
};

const JuniorsStats = (props) => {
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [juniorStats, setJuniorStats] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  const [juniorPastStats, setJuniorPastStats] = useState([]);

  const handleShowStats = async () => {
    if (showList) {
      setShowList(!showList);
      return;
    }
    setShowList(!showList);
    setLoading(true);
    setShowDownloadBtn(false);
    try {
      const result = await getLoginActivity();
      result.map((user) => {
        user.timeSpent =
          props.userOnlineTime[user.uid]?.hour +
          props.userOnlineTime[user.uid]?.minute;
      });
      setLoading(false);
      setJuniorStats(result);
      setJuniorPastStats([]);
      setShowDownloadBtn(true);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleChangeDate = async (changedDate) => {
    const timestamp = new Date(changedDate).getTime();

    if (timestamp > new Date().getTime()) {
      console.log("future date");
      return;
    }
    setSelectedDate(changedDate);
    setShowDownloadBtn(false);
    if (checkIsSameDay(timestamp, new Date().getTime())) {
      setLoading(true);
      const result = await getLoginActivity();
      result.map((user) => {
        user.timeSpent =
          props.userOnlineTime[user.uid]?.hour +
          props.userOnlineTime[user.uid]?.minute;
      });
      setLoading(false);
      setJuniorStats(result);
      setJuniorPastStats([]);
      setShowDownloadBtn(true);
      return;
    }

    await handleGetPastStats(changedDate);
    setShowDownloadBtn(true);
    return;
  };

  const parseTimeStampToHour = (timeSpent) => {
    if (!timeSpent) return "0 min";

    return (
      (timeSpent < 3600000 ? "" : Math.floor(timeSpent / 3600000) + " hr ") +
      (Math.floor(timeSpent / 60000) % 60) +
      " min"
    );
  };
  const ChangetimeStampToHour = (stats) => {
    return stats.map((stat) => {
      stat.timeSpent = parseTimeStampToHour(stat.timeSpent);

      return stat;
    });
  };
  const handleGetPastStats = async (date) => {
    setLoading(true);
    const newDate = new Date(date);
    const dateStr = `${newDate.getUTCFullYear()}${newDate.getUTCMonth()}${newDate.getUTCDate()}`;

    try {
      const payload = { dateStr };
      const result = await getLoginActivity(payload);

      setLoading(false);
      setJuniorPastStats(ChangetimeStampToHour(result));
      setJuniorStats([]);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    setSelectedDate(getDateYYYYMMDD());
  }, [showList]);

  useEffect(() => {
    if (
      showList &&
      checkIsSameDay(new Date(selectedDate).getTime(), new Date().getTime())
    ) {
      juniorStats.map((user) => {
        user.timeSpent =
          props.userOnlineTime[user.uid]?.hour +
          props.userOnlineTime[user.uid]?.minute;
      });
      setJuniorStats(juniorStats.filter(()=>true))
    }
  }, [props.userOnlineTime]);

  return (
    <div className="juniors_stats">
      <button
        className={showList ? "juniors_show_list_btn" : "juniors_stats_btn"}
        onClick={() => {
          handleShowStats();
        }}
      >
        team stats
      </button>
      {showList ? (
        <div className="junior_stats_wrapper">
          <div className="junior_stats_container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <h2 style={{ margin: "0" }}>Team Stats</h2>
              <CrossLogo
                style={{ cursor: "pointer" }}
                onClick={handleShowStats}
              />
            </div>
            <div className="date_picker_container">
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => {
                  handleChangeDate(e.target.value);
                }}
                className="date_picker"
              />
              {showDownloadBtn ? (
                <CSVLink
                  data={juniorPastStats.length ? juniorPastStats : juniorStats}
                  headers={headers}
                  filename={`juniorStats-${selectedDate}`}
                  className="downloadCsvBtn"
                >
                  Download stats
                </CSVLink>
              ) : null}
            </div>

            {loading ? (
              <LoaderLogo
                className="spinner"
                style={{
                  width: "25px",
                  height: "25px",
                }}
              />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <span className="junior_name">Name</span>
                  <Tooltip
                    placement="top"
                    title="Total Calls"
                    color={"#f1a200"}
                  >
                    <span className="stats_box">
                      <CallLogo
                        style={{ width: "25px", height: "25px" }}
                        className="junor_stats_call_logo"
                      />{" "}
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="Total hand raises"
                    color={"#f1a200"}
                  >
                    <span className="stats_box">
                      {/* <RaiseHand /> */}
                      <img
                        src={RaiseHandPng}
                        alt="handRaise"
                        style={{ width: "25px", height: "25px" }}
                      />
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="Total available time"
                    color={"#f1a200"}
                  >
                    <span className="stats_box">
                      <ClockLogo style={{ width: "25px", height: "25px" }} />
                    </span>
                  </Tooltip>
                </div>
                {juniorStats.map((user) => {
                  if (props.userOnlineTime[user.uid])
                    return (
                      <div
                        style={{
                          color: props.userOnlineTime[user.uid].online
                            ? "#f1a200"
                            : null,
                        }}
                        key={`user-${user.uid}`}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "5px",
                          }}
                        >
                          <span className="junior_name">
                            {user.displayName}{" "}
                          </span>
                          <span className="stats_box">
                            <p style={{ marginBottom: "0px" }}>
                              {user.lineOnCount}
                            </p>
                          </span>
                          <span className="stats_box">
                            <p
                              style={{
                                marginBottom: "0px",
                                textAlign: "center",
                              }}
                            >
                              {user.nudgeCount}
                            </p>
                          </span>
                          <span className="stats_box">
                            <p style={{ marginBottom: "0px" }}>
                              <span>
                                {props.userOnlineTime[user.uid]?.hour}{" "}
                              </span>
                              {props.userOnlineTime[user.uid]?.minute}
                            </p>
                          </span>
                        </div>
                      </div>
                    );
                })}
                {juniorPastStats.map((user) => {
                  return (
                    <div key={`user-${user.uid}`}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <span className="junior_name">{user.displayName} </span>
                        <span className="stats_box">
                          <p style={{ marginBottom: "0px" }}>
                            {user.lineOnCount}
                          </p>
                        </span>
                        <span className="stats_box">
                          <p
                            style={{
                              marginBottom: "0px",
                              textAlign: "center",
                            }}
                          >
                            {user.nudgeCount}
                          </p>
                        </span>
                        <span className="stats_box">
                          <p style={{ marginBottom: "0px" }}>
                            <span>{user.timeSpent}</span>
                          </p>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    uid: state.user.uid,
    userOnlineTime: state.userOnlineTime,
  };
};

export default connect(mapStateToProps, null)(JuniorsStats);

import { PlugWsUsersHandler } from "./Handlers/Users/index.js";
import { PlugWsGroupsHandler } from "./Handlers/Groups/index.js";
import { PlugWsSignalsHandler } from "./Handlers/Signals/index.js";
import io from "socket.io-client";
import { delay, isElectron } from "../../common/Utils.js";

export class PlugWsClient {
  constructor() {
    this.socket = {};
    this.groupHandler = null;
    this.userHandler = null;
    this.signalHandler = null;
    this.handlerCreated = false;
    this.store = null;
    this.actions = null;

    this.lastReconnectionTime = 0;
    this.getTokenActions = null;
    this.wsActions = null;
    this.janusActions = null;
  }

  // init call for initialising the socket.
  async init(
    store,
    actions,
    wsActions,
    janusActions,
    localStorageAction,
    getTokenActions
  ) {
    this.store = store;
    this.actions = actions;
    this.getTokenActions = getTokenActions;
    this.wsActions = wsActions;
    this.janusActions = janusActions;
    this.localStorageAction = localStorageAction;

    if (this.socket.hasOwnProperty("connected")) {
      this.socket.destroy();
    }
    const token = await this.getTokenActions();
    this.socket = await io.connect(
      // "https://ws-server-futfblglha-em.a.run.app",
      // "localhost:8080",
      "https://ws.plugapp.net",

      {
        auth: { token },
        query: "platform=web",

        reconnection: false,

        transports: ["websocket"],
      }
    );

    this.socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
      this.reconnect();
    });

    this.socket.on("connect", async () => {
      console.log("connected", this.socket);
      this.store.dispatch(this.actions.setSocketConnected("connected"));
      console.log("new Instance has been created");

      this.groupHandler = new PlugWsGroupsHandler(
        this.socket,
        store,
        actions,
        wsActions,
        janusActions,
        localStorageAction
      );
      this.userHandler = new PlugWsUsersHandler(this.socket, store, actions);
      this.signalHandler = new PlugWsSignalsHandler(
        this.socket,
        store,
        actions,
        janusActions,
        localStorageAction
      );

      await this.groupHandler.subscribe();
      // find if the plug is on then send user_online;
      // and if the line is on then send signal_lineon;

      if (isElectron() || this.store.getState().plugON) {
        this.store.dispatch(this.wsActions.setPlugOn(true));
      }

      if (store.getState().userLineOnInGroup) {
        store.dispatch(
          wsActions.setLineOn({ groupid: store.getState().userLineOnInGroup })
        );
      }
    });

    this.socket.on("disconnect", async (err) => {
      console.log("disconnected", err);
      store.dispatch(actions.setSocketConnected("disconnected"));

      if (new Date().getTime() - this.lastReconnectionTime < 2000) return;
      this.lastReconnectionTime = new Date().getTime();

      await this.reconnect();
    });

    // Listen for reconnect event
    this.socket.io.on("reconnect", () => {
      console.log("reconnecting");
    });
  }

  async reconnect() {
    console.log("reconnecting the socket");
    let token = await this.getTokenActions();
    console.log(token);
    if (this.socket.connected) {
      this.store.dispatch(this.actions.setSocketConnected("connected"));
      return ;
    }
    this.socket.destroy();
    this.socket = await io.connect(
      // "https://ws-server-futfblglha-em.a.run.app",
      // "localhost:8080",
      "https://ws.plugapp.net",

      {
        auth: { token },
        query: "platform=web",
        reconnection: false,
        transports: ["websocket"],
      }
    );
    this.socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
      this.reconnect();
    });
    this.socket.on("connect", async () => {
      console.log("connected", this.socket);
      this.store.dispatch(this.actions.setSocketConnected("connected"));

      console.log("new Instance has been created");

      this.groupHandler = new PlugWsGroupsHandler(
        this.socket,
        this.store,
        this.actions,
        this.wsActions,
        this.janusActions,
        this.localStorageAction
      );
      this.userHandler = new PlugWsUsersHandler(
        this.socket,
        this.store,
        this.actions
      );
      this.signalHandler = new PlugWsSignalsHandler(
        this.socket,
        this.store,
        this.actions,

        this.janusActions,
        this.localStorageAction
      );

      await this.groupHandler.subscribe();
      // if (this.store.getState().plugON) {
      //   console.log("calling this while plugOn");
      //   this.userHandler.subscribe();
      //   this.signalHandler.subscribe();
      // }

      // find if the plug is on then send user_online;
      // and if the line is on then send signal_lineon;
      if (isElectron() || this.store.getState().plugON) {
        this.store.dispatch(this.wsActions.setPlugOn(true));
      }
      if (this.store.getState().userLineOnInGroup) {
        this.store.dispatch(
          this.wsActions.setLineOn({
            groupid: this.store.getState().userLineOnInGroup,
          })
        );
      }
      if (this.store.getState().pendingLineOff) {
        this.store.dispatch(
          this.wsActions.setLineOff({
            groupid: this.store.getState().pendingLineOff,
          })
        );
      }

      this.socketReconnected = false;
    });

    this.socket.on("disconnect", (err) => {
      console.log("disconnected", err);
      this.store.dispatch(this.actions.setSocketConnected("disconnected"));

      if (new Date().getTime() - this.lastReconnectionTime < 2000) return;
      this.lastReconnectionTime = new Date().getTime();
      this.reconnect();
    });
    this.socketReconnected = true;
    this.handlerCreated = false;
    console.log(this.socket);
  }

  isConnected() {
    return this.socket.connected;
  }

  disconnect() {
    return new Promise(async (res, rej) => {
      try {
        await this.store.dispatch(this.wsActions.setPlugOff());
        await this.socket.disconnect();
        this.handlerCreated = false;
        res();
      } catch (e) {
        rej(e);
      }
    });
  }

  async subscribe(type) {
    switch (type) {
      case "groups":
        return this.groupHandler.subscribe();
      case "users":
        if (!this.userHandler) await delay(1000);
        return this.userHandler.subscribe();
      case "signals":
        if (!this.signalHandler) await delay(1000);
        return this.signalHandler.subscribe();
      default:
        return;
    }
  }

  async makeRequest(on, type, payload) {
    if (on === "groups") await this.groupHandler.makeRequest(type, payload);
    else if (on === "signals")
      await this.signalHandler.makeRequest(type, payload);
    else if (on === "users" && this.userHandler) {
      const res = await this.userHandler.makeRequest(type,payload);
      return res;
    }
  }

  async reAuthenticate(accessToken, user) {
    console.log(accessToken);
    this.socket.emit(
      "authenticate",
      { access_token: accessToken },
      (err, response) => {
        console.log(err, response);
        console.log(this.socket, user);
        this.store.dispatch(this.actions.setUserNewAccessToken(user));
        this.socket.auth.token = accessToken;
      }
    );
  }
}

import React from "react";
import { connect } from "react-redux";
import { ExpandLogo } from "../../../assets";
import { Avatar } from "antd";
import { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";
import {
  setNewMsgInGroup,
  showChatBoxAction,
  setUserLineOnInGroup,
  setMissedLineOnGroup
} from "../../../Redux/Actions/Action";
import {
  activateGroup,
  showRecorder,
  showLineOnPopUp,
} from "../../../Redux/Actions/Action";
import { setLineOff, setLineOn , sendMessageAction} from "../../../Redux/Actions/WsActions";
import { lineOffAction } from "../../../Redux/Actions/JanusClientActions";
import { hash } from "../../../logic/common/Utils";
import "./SeniorLineOnWrapper.css";
import { useState, useEffect, useRef } from "react";
import SeniorLineOn from "../SeniorLineOn/SeniorLineOn";
import { joinJanusRoom } from "../../../Redux/Actions/JanusClientActions";
import { MicLogo } from "../../../assets/index.js";
const colorList = [
  "#f56a90",
  "#7265e6",
  "#ffbf9f",
  "#00a2ae",
  "#f1a200",
  "#00a2ae",
  "#7265e6",
  "#f56a90",
  "#ffbf9f",

  "#ffbf9f",
  "#f1a200",
];

const onlineStyle = {
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: "#00bf19",
  border: "4px solid #f2fcf3",
  position: "absolute",
  right: "41%",
};
const busyStyle = {
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: "#d63131",
  border: "4px solid #fff0f0",
  position: "absolute",
  right: "41%",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bolder",
  lineHeight: "3",
};

function SeniorLineOnWrapper(props) {
  const [expand, setExpand] = useState(false);
  const [startTimer, setStartTimer] = useState(true);
  const [counter, setCounter] = useState(0);
  const [seconds, setSeconds] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    let interval;
    if (startTimer) {
      interval = setInterval(() => {
        let count = counter + 1;
        let min_ = Math.floor(count / 60);
        let sec_ = count % 60;
        // console.log(count);
        min_ = String(min_).length == 1 ? `0${min_}` : String(min_);
        sec_ = String(sec_).length == 1 ? `0${sec_}` : String(sec_);
        setCounter(count);
        setMinutes(min_);
        setSeconds(sec_);
      }, 1000);
    } else {
      // console.log("interval is clear");
      clearInterval(interval);
    }

    return () => {
      // console.log("this is called");
      clearInterval(interval);
    };
  }, [counter, startTimer]);

  async function JoinRoom() {
    // setShowUnseenPopUp(false);

    if (props.janusRoomJoinning) {
      console.log(
        "room joining process already in room",
        props.janusRoomJoinning
      );
      return;
    }

    await props.joinJanusRoom(props?.activeGroup?.groupid);

    props
      .setLineOn({ groupid: props?.activeGroup?.groupid })
      .then(() => { })
      .catch((e) => console.log(e));
  }

  const handleIfMissedCall= async()=>{
    console.log(props.missedLineOn[props.activeGroup.groupid])
    if(props.missedLineOn[props.activeGroup.groupid]){
      // console.log("handle missed call msg")
      let payload = {};
      payload.groupid = props.activeGroup.groupid;
      payload.displayName = props?.user?.displayName;
      payload.type = "MISSEDCALL";
      payload.message="missed call";

      await props.sendMessageAction(payload);
      props.setMissedToFalse({groupid:props.activeGroup.groupid,isMissed:false})
      
    }
  }

  return (
    <React.Fragment>
      {expand || props.activeGroup.istemp ? (
        <SeniorLineOn
          isLineOnBox={true}
          showCollapse={props.activeGroup.istemp ? false : true}
          collpase={() => {
            setExpand(false);
          }}
          startTimer={() => {
            setCounter(0);
            setMinutes("00");
            setSeconds("00");
            setStartTimer(true);
          }}
          closeTimer={() => {
            setStartTimer(false);
          }}
        />
      ) : (
        <div className="senior_wrapper_container">
          <div
            className="senior_wrapper_box"
            style={{
              animation: showAnimation
                ? "confirmationBoxAnimation 0.4s linear 1"
                : "",
            }}
          >
            <ExpandLogo
              style={{
                alignSelf: "flex-end",
                marginRight: "20px",
                marginTop: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setExpand(true);
                setShowAnimation(false);
              }}
            />
            {props.activeGroup.members.filter(user => user.uid != props.user.uid).slice(0, 1).map((user, idx) => {
              if (user.uid != props.user.uid) {
                return (
                  <div
                    key={user.uid + "user" + { idx }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <React.Fragment>
                      {user.photoURL ? (
                        <img
                          src={user.photoURL}
                          className={
                            props.plugON &&
                              props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                              user?.uid
                              ]
                              ? "line_on_animation"
                              : ""
                          }
                          style={{
                            height: "60px",
                            width: "60px",
                            padding: "2px",
                            borderRadius: "50%",
                            border:
                              props.plugON &&
                                props.lineOnStatus?.[
                                props?.activeGroup?.groupid
                                ]?.[user?.uid]
                                ? "solid 3px #00bf19"
                                : "solid 3px  rgba(255,255,255,0)",
                            outline:
                              props.plugON &&
                                props.lineOnStatus?.[
                                props?.activeGroup?.groupid
                                ]?.[user?.uid]
                                ? "2px solid  #00bf1921"
                                : "2px solid  rgba(255,255,255,0)",
                            outlineOffset: "3px",
                          }}
                        />
                      ) : (
                        <Avatar
                          style={{
                            borderRadius: "50%",
                            padding: "2px",
                            backgroundClip: "content-box",

                            border:
                              props.plugON &&
                                props.lineOnStatus?.[
                                props?.activeGroup?.groupid
                                ]?.[user?.uid]
                                ? "solid 3px #00bf19"
                                : "solid 3px  rgba(255,255,255,0)",
                            outline:
                              props.plugON &&
                                props.lineOnStatus?.[
                                props?.activeGroup?.groupid
                                ]?.[user?.uid]
                                ? "solid 2px #00bf1921"
                                : "solid 2px rgba(255,255,255,0)",
                            outlineOffset: "3px",
                            backgroundColor:
                              colorList[hash(user.displayName) % 11],
                            cursor: "pointer",
                            fontSize: "30px",
                            width: "60px",
                            height: "60px",
                            lineHeight: "2",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                          className={
                            props.plugON &&
                              props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                              user?.uid
                              ]
                              ? "line_on_animation"
                              : ""
                          }
                        >
                          {user?.displayName?.[0]?.toUpperCase() ?? "@"}
                        </Avatar>
                      )}
                      {props.plugON &&
                        !props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                        user?.uid
                        ] &&
                        props.onlineStatus?.[user?.uid]?.online ? (
                        props.busyStatus?.[user?.uid] ? (
                          <span style={busyStyle} key={idx}>
                            -
                          </span>
                        ) : (
                          <span style={onlineStyle} key={idx}></span>
                        )
                      ) : null}
                    </React.Fragment>

                    <span
                      style={{
                        display: "inline-block",
                        marginLeft: "10px",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontWeight: "bolder",
                      }}
                    >
                      {user?.displayName ?? user?.name}
                    </span>
                  </div>
                );
              }
            })}
            <div style={{ marginLeft: "10px", marginBottom: "10px" }}>
              <span style={{ fontWeight: "bolder", fontSize: "16px" }}>
                {minutes}
              </span>
              <span style={{ fontWeight: "bolder", fontSize: "16px" }}>:</span>
              <span style={{ fontWeight: "bolder", fontSize: "16px" }}>
                {seconds}
              </span>
            </div>
            {props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
              props?.user?.uid
            ] ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "40px",
                }}
              >
                <button
                  className="end_call_btn"
                  onClick={async () => {
                    props.closeLineOnAction(props.activeGroup.groupid);
                    props.closeChatBoxAction(props?.activeGroup?.groupid);
                    setStartTimer(false);
                   await handleIfMissedCall()
                   
                  }}
                >
                  End Call
                </button>
                {window.STREAM && window.STREAM.active && window.STREAM.getTracks()[0].enabled ? (
                  <AudioOutlined
                    className="mute_button"
                    style={{
                      transform: "scale(1.6)",
                      backgroundColor: "rgb(241, 162, 0)",
                      color: "white",
                      padding: "5px",
                      borderRadius: "5px",
                      marginLeft: "9px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.STREAM.getTracks()[0].enabled = false;
                    }}
                  />
                ) : (
                  <AudioMutedOutlined
                    className="unmute_button"
                    style={{
                      transform: "scale(1.6)",
                      backgroundColor: "#ddd",
                      color: "black",
                      padding: "5px",
                      borderRadius: "5px",
                      marginLeft: "9px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.STREAM.getTracks()[0].enabled = true;
                    }}
                  />
                )}
              </div>
            ) : (
              <button
                className="end_call_btn"
                style={{ backgroundColor: "#f1a200" }}
                onClick={() => {
                  JoinRoom();
                  setCounter(0);
                  setMinutes("00");
                  setSeconds("00");
                  setStartTimer(true);
                }}
              >
                Call
              </button>
            )}
            {/* <button
              className="end_call_btn"
              onClick={() => {
                props.closeLineOnAction(props?.activeGroup?.groupid);
              }}
            >
              End Call
            </button> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  closeChatBoxAction: (groupid) => {
    dispatch(setNewMsgInGroup({ [groupid]: false }));
    dispatch(showChatBoxAction(false));
    dispatch(activateGroup({ groupName: "", members: [], groupid: "" }));
    dispatch(showRecorder(false));
    dispatch(showLineOnPopUp(false));
  },
  closeLineOnAction: (groupid) => {
    dispatch(setNewMsgInGroup({ [groupid]: false }));
    dispatch(setLineOff({ groupid: groupid }));
    dispatch(lineOffAction(groupid, true));
    dispatch(setUserLineOnInGroup(null));
  },
  sendMessageAction: (payload) => {
    return dispatch(sendMessageAction(payload));
  },
  setMissedToFalse:(payload)=>dispatch(setMissedLineOnGroup(payload)),
  joinJanusRoom: (groupid) => dispatch(joinJanusRoom(groupid)),
  setLineOn: (payload) => dispatch(setLineOn(payload)),
  //   makeRequest: (type, payload) => dispatch(makeRequest(type, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeniorLineOnWrapper);

import React, { useEffect } from "react";
import { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { getDownloadableUrl } from "../../../Redux/Actions/firebaseActions";
import {
  LoadingOutlined,
  PlayCircleFilled,
  PlayCircleTwoTone,
  setTwoToneColor,
} from "@ant-design/icons";
import "./ChatAudio.css";
import { useRef } from "react";
setTwoToneColor("#aaa");
export default function ChatAudio(props) {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const audioRef = useRef();
  const handleDownloadUrl = async () => {
    setLoading(true);

    getDownloadableUrl(props.url)
      .then((url) => {
        setUrl(url);
        setLoading(false);

        // console.log(audioRef.current);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.autoPlay) {
      console.log(props.autoPlay);
      if (!url) {
        handleDownloadUrl();
      }
    }
  }, [props.autoPlay]);

  useEffect(() => {
    if (url && !loading) {
      // console.log(audioRef.current);
      audioRef.current.onloadeddata = () => {
        audioRef.current.play();
      };
      audioRef.current.onended = () => {
        console.log("ended");
        props.setLastRead(props.createdat);
      };
    }
  }, [loading]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      {loading ? <LoadingOutlined /> : null}

      {!url ? (
        <div
          style={{
            display: "flex",
            width: "300px",
            height: "50px",
            border:
              props.createdat <= props.lastReadTimestamp || props.isSender
                ? "2px solid #ddd"
                : "2px solid #09d10970",
            backgroundColor:
              props.createdat <= props.lastReadTimestamp || props.isSender
                ? "#ddd"
                : "#09d10930",
            borderRadius: "20px",
            justifyContent: "flex-start",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            handleDownloadUrl();
          }}
        >
          {props.createdat <= props.lastReadTimestamp || props.isSender ? (
            <PlayCircleTwoTone
              style={{
                fontSize: "30px",
                color: "black",
                marginLeft: "10px",
              }}
            />
          ) : (
            <PlayCircleFilled
              style={{
                fontSize: "30px",
                color: "#09d109e0",
                marginLeft: "10px",
              }}
              onClick={() => {
                handleDownloadUrl();
              }}
            />
          )}

          <p
            style={{
              marginBottom: "0px",
              marginLeft: "20px",
              fontWeight: "bolder",
            }}
          >
            play voice note
          </p>
        </div>
      ) : (
        <audio src={url} controls ref={audioRef} />
      )}

      {/* {url ? null : (
        <DownloadOutlined
          className="download_media_url"
          style={{ fontSize: "25px" }}
          onClick={() => {
            handleDownloadUrl();
          }}
        />
      )} */}
    </div>
  );
}

import React, { useState } from "react";
import "./CreateGroup.css";

import {
  CrossLogo,
  ArrowLogo,
  CancelLogo,
  cubeLogo,
} from "../../../assets/index.js";
import { connect } from "react-redux";
import { showCreateGroup } from "../../../Redux/Actions/Action";
import { Avatar, message } from "antd";
import { generateId, hash } from "../../../logic/common/Utils";
import { createGroup } from "../../../Redux/Actions/WsActions";
const regexEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
const colorList = [
  "#f56a90",
  "#7265e6",
  "#ffbf9f",
  "#00a2ae",
  "#f1a200",
  "#00a2ae",
  "#7265e6",
  "#f56a90",
  "#ffbf9f",

  "#ffbf9f",
  "#f1a200",
];

function CreateGroup(props) {
  const [next, setNext] = useState(false);
  const [groupName, setName] = useState("");
  const [userList, setUser] = useState(props.userEmails);
  const [userEmails, setUserEmails] = useState(props.userEmails);
  const [userEmail, setEmail] = useState("");
  const [selectedList, setSelectedList] = useState([
    { email: props.user.email, uid: props.user.uid },
  ]);

  const handleSearchUser = (value) => {
    setEmail(value);
    let newUser = [];
    if (value === "" || value === null) {
      setUser(props.userEmails);
    } else {
      newUser = userEmails.filter((user) => {
        return user.email.toLowerCase().includes(value.toLowerCase());
      });

      if (newUser.length === 0 || newUser.length === 1) {
        if (value.match(regexEmail)) {
          newUser.push({
            email:value
          });
        }
      }
      setUser(newUser);
    }
  };

  const handleAddUser = (user_obj) => {
    if (selectedList.some((e) => user_obj.uid === e.uid)) {
      message.error("already added");

      return;
    }
    setSelectedList((prev) => [
      { email: user_obj.email, uid: user_obj.uid },
      ...prev,
    ]);
  };

  const handleCreateGroup = async () => {
    let payload = {};
    payload.groupid = "group-" + generateId(16);
    payload.name = groupName;

    payload.members = [];
    selectedList.forEach((obj) => {
      let tempObj = {};
      tempObj.email = obj.email.toLowerCase();
      tempObj.uid = obj.uid;
      tempObj.name = obj.email.split("@")[0];
      payload.members.push(tempObj);
    });

    // console.log(payload);
    await props.createGroup(payload);
    setName("");
    setNext(false);
    setUser([]);
    setEmail("");
    setSelectedList([]);
    props.closeCreateGroupAction();
  };

  const removeUserEmail = (user_obj) => {
    setSelectedList((prev) => {
      prev = prev.filter((e) => e.uid !== user_obj.uid);
      return prev;
    });
  };
  return (
    <div className="modal_box" style={{ display: "flex" }}>
      <div className="modal_container">
        <CrossLogo
          className="modal_crg_cross_logo"
          onClick={() => {
            props.closeCreateGroupAction();
          }}
        />
        {next ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ArrowLogo
              className="modal_crg_arr_logo"
              onClick={() => {
                setNext(false);
              }}
            />
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "30px",
                fontWeight: "bolder",
                textAlign: "center",
                marginTop: "60px",
              }}
            >
              Add Members in
              <span
                style={{
                  color: "#f1a200",
                  fontWeight: "bolder",
                  fontFamily: "Inter",
                  marginLeft: "5px",
                }}
              >
                {groupName}
              </span>
            </h2>
            <div className="modal_crg_add_box">
              <div className="modal_crg_add  modal_mobile_view_crg">
                <input
                  type="text"
                  placeholder="search by Email or Name"
                  value={userEmail}
                  onChange={(e) => {
                    handleSearchUser(e.target.value);
                  }}
                />
                <div
                  style={{
                    overflowY: "auto",
                    width: "90%",
                    maxHeight: "240px",
                    paddingRight: "10px",
                  }}
                >
                  {/* userlist map need will be here with key={`user-li-${idx}`} */}

                  {userList.map((e, idx) => {
                    return (
                      <div className="search_user_row" key={`user-li-${idx}`}>
                        <Avatar
                          style={{
                            borderRadius: "50%",
                            padding: "2px",
                            border: "solid 3px  rgba(255,255,255,0)",
                            outline: "2px solid  rgba(255,255,255,0)",
                            outlineOffset: "3px",
                            backgroundColor:
                              colorList[hash(e.email.split("@")[0]) % 11],
                            cursor: "pointer",
                            fontSize: "20px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          {e.email[0].toUpperCase()}
                        </Avatar>
                        <p
                          style={{
                            marginBottom: "0px",
                            flex: 1,
                            marginLeft: "5px",
                            width: "60%",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontWeight: "bolder",
                          }}
                        >
                          {e.email}
                        </p>
                        {/* {obj.selected ? (
                        <button className="search_user_remove_button">
                          Remove
                        </button>
                      ) : ( */}
                        <button
                          type="text"
                          className="search_user_add_button"
                          onClick={() => {
                            handleAddUser(e);
                          }}
                        >
                          + Add
                        </button>
                        {/* )} */}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="modal_crg_list">
                <h3>Group Members: {selectedList.length}</h3>
                <div
                  style={{
                    overflowY: "auto",
                    width: "80%",
                    height: "240px",
                    paddingRight: "10px",
                  }}
                >
                  {selectedList.map((obj, idx) => {
                    return (
                      <div
                        className="search_user_row"
                        key={`selected-li-${idx}`}
                      >
                        <Avatar
                          style={{
                            borderRadius: "50%",
                            padding: "2px",
                            border: "solid 3px  rgba(255,255,255,0)",
                            outline: "2px solid  rgba(255,255,255,0)",
                            outlineOffset: "3px",
                            backgroundColor:
                              colorList[hash(obj.email.split("@")[0]) % 11],
                            cursor: "pointer",
                            fontSize: "20px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          {obj.email[0].toUpperCase()}
                        </Avatar>
                        <p
                          style={{
                            marginBottom: "0px",
                            flex: 1,
                            marginLeft: "10px",
                            width: "60%",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontWeight: "bolder",
                          }}
                        >
                          {obj.email}
                        </p>
                        <CancelLogo
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            removeUserEmail(obj);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>

                <button
                  style={{
                    backgroundColor: "#f1a200",
                    border: "none",
                    outline: "none",
                    color: "white",
                    padding: "10px 25px",
                    fontSize: "18px",
                    borderRadius: "15px",
                    cursor: "pointer",
                    margin: "20px 15px",
                    opacity: selectedList.length === 0 ? 0.4 : 1,
                    position: "absolute",
                    right: "10px",
                    bottom: "-80px",
                  }}
                  onClick={() => {
                    if (!selectedList.length) return;
                    handleCreateGroup();
                  }}
                  className="create_grp_btn"
                >
                  Create Group
                </button>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <img src={cubeLogo} alt="cube" className="modal_crg_img" />
            <h2
              style={{
                fontWeight: "bolder",
                fontFamily: "Inter",
                fontSize: "36px",
                color: "#121212",
              }}
            >
              Group Name
            </h2>
            <input
              type="text"
              className="modal_crg_input"
              value={groupName}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />

            <button
              className="modal_crg_button"
              onClick={() => {
                setNext(true);
              }}
            >
              Next
            </button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  closeCreateGroupAction: () => dispatch(showCreateGroup(false)),
  // makeRequest: (type, payload) => dispatch(makeRequest(type, payload)),
  createGroup: (payload) => {
    return dispatch(createGroup(payload));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);

import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./Redux/Reducer/Reducer";
import { Provider } from "react-redux";
import LogRocket from "logrocket";
const store = createStore(
  reducer,
  applyMiddleware(thunk, LogRocket.reduxMiddleware())
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App store={store} />
  </Provider>
  // </React.StrictMode>
);

import LocalStorageClient from "../../logic/modules/LocalStorageClient";

const LocalStorageClientInstance = new LocalStorageClient();

export const getLastReadRecording = (uid) => {
  return LocalStorageClientInstance.getLastReadRecording(uid);
};

export const setLastReadRecording = (payload, uid) => {
  LocalStorageClientInstance.setLastReadRecording(payload, uid);
};

export const getLastMsgInGroup = (uid) => {
  return LocalStorageClientInstance.getLastMsgInGroupTimestamp(uid);
};

export const setLastMsgInGroup = (groupid, timestamp, uid) => {
  let payload = getLastMsgInGroup(uid);
  payload[groupid] = timestamp;
  LocalStorageClientInstance.setLastMsgInGroupTimeStamp(payload, uid);
};
export const getLastVisitDay = (uid) => {
  const time = LocalStorageClientInstance.getLastVisitDay(uid);

  return time;
};
export const setLastVisitDay = (uid) => {
  LocalStorageClientInstance.setLastVisitDay(uid);
};

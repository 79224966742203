import React from "react";
import { LinkPreview } from "../LinkPreview/LinkPreview.tsx";
import { hash } from "../../../logic/common/Utils";
import "./ParsedMsg.css";
export default function ParsedMsg(props) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return props.text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return (
        <div key={hash(part)}>
          <LinkPreview
            url={part}
            width="60%"
            className="linkPreview_container"
          />
          <a target="_blank" href={part}>
            <span className="msg_link">{part}</span>
          </a>
        </div>
      );
    }
    return part;
  });
}

import FirebaseClient from "../../logic/modules/FirebaseClient";
import { newGroupChat, setLastRecordingTimestamp } from "./Action";
const FirebaseClientInstance = new FirebaseClient();

export const initializeFirebaseClient = (payload) => {
  return (dispatch) => {
    FirebaseClientInstance.init(payload);
  };
};

export const hideChatsLoading = (payload) => {
  return {
    type: "SET_HIDE_CHATS_LOADING",
    payload: payload,
  };
};
export const setUser = (user) => {
  FirebaseClientInstance.setUser(user);
};

export const trackEventActions = (eventName, payload) => {
  FirebaseClientInstance.trackEvent(eventName, payload);
};

export const getTokenActions = () => {
  return FirebaseClientInstance.getToken();
};

export const getGroupsLastMessage = (groupid) => {
  return async (dispatch, getState) => {
    let useruid = getState().user.uid;
    let result = await FirebaseClientInstance.fetchMessage(groupid, 0);
    let lastMsgTimeStamp = 0;
    let lastRecordingTimestamp = 0;
    if (result === null) {
      dispatch(
        newGroupChat({
          groupid: [groupid],
          message: [],
          oldMessage: [],
        })
      );
      dispatch(setLastRecordingTimestamp({ groupid: [groupid], timestamp: 0 }));
      return 0;
    }
    let chatsArr = [];
    Object.keys(result).forEach((key) => {
      chatsArr.push(result[key]);

      if (result[key].senderuid !== useruid) {
        if (lastMsgTimeStamp < result[key].createdat) {
          lastMsgTimeStamp = result[key].createdat;
        }
      }
      if (result[key].mediaurl && result[key].senderuid !== useruid) {
        if (lastRecordingTimestamp < result[key].createdat) {
          lastRecordingTimestamp = result[key].createdat;
        }
      }
    });
    chatsArr.sort((a, b) => {
      return b.createdat - a.createdat;
    });

    dispatch(
      newGroupChat({
        groupid: [groupid],
        message: [...chatsArr],
        oldMessage:  [],
      })
    );
    dispatch(
      setLastRecordingTimestamp({
        groupid: [groupid],
        timestamp: lastRecordingTimestamp ? lastRecordingTimestamp : 1,
      })
    );

    if (chatsArr.length < 100) {
      dispatch(hideChatsLoading({ groupid: [groupid] }));
    }
    return lastMsgTimeStamp ? lastMsgTimeStamp : 1;
  };
};

export const getGroupMessageHistory = (groupid, moreMsg) => {
  return async (dispatch, getState) => {
    let groupMsg = getState().groupsChats?.[groupid] ?? [];
    let useruid = getState().user.uid;
    // console.log(groupid, groupMsg);
    let result = await FirebaseClientInstance.fetchMessage(
      groupid,
      groupMsg.length
    );
    let lastRecordingTimestamp = 0;
    // console.log(result);
    let lastMsgTimeStamp = groupMsg.length
      ? groupMsg[groupMsg.length - 1].createdat
      : null;
    // console.log(lastMsgTimeStamp);
    if (result === null) {
      dispatch(
        newGroupChat({
          groupid: [groupid],
          message: [],
          oldMessage: [],
        })
      );
      dispatch(setLastRecordingTimestamp({ groupid: [groupid], timestamp: 0 }));
      return;
    }
    let chatsArr = [];
    Object.keys(result).forEach((key) => {
      if (
        result[key].createdat <
        (lastMsgTimeStamp ? lastMsgTimeStamp : Date.now())
      ) {
        chatsArr.push(result[key]);
      }
      if (!lastMsgTimeStamp) {
        if (result[key].mediaurl && result[key].senderuid !== useruid) {
          if (lastRecordingTimestamp < result[key].createdat) {
            lastRecordingTimestamp = result[key].createdat;
          }
        }
      }
    });
    chatsArr.sort((a, b) => {
      return b.createdat - a.createdat;
    });
    // console.log(chatsArr, lastRecordingTimestamp);

    dispatch(
      newGroupChat({
        groupid: [groupid],
        message: moreMsg ? [] : [...chatsArr],
        oldMessage: moreMsg ? [...chatsArr] : [],
      })
    );
    dispatch(
      setLastRecordingTimestamp({
        groupid: [groupid],
        timestamp: lastRecordingTimestamp ? lastRecordingTimestamp : 1,
      })
    );

    if (chatsArr.length < 100) {
      dispatch(hideChatsLoading({ groupid: [groupid] }));
    }
    return lastRecordingTimestamp ? lastRecordingTimestamp : 1;
  };
};

export const uploadFileToCloud = async (file, fileType) => {
  return new Promise(async (res, rej) => {
    try {
      const url = await FirebaseClientInstance.uploadFile(
        file,

        fileType
      );
      res(url);
    } catch (e) {
      rej(e);
    }
  });
};
export const getDownloadableUrl = async (path) => {
  return new Promise(async (res, rej) => {
    try {
      const url = await FirebaseClientInstance.getUrl(path);
      res(url);
    } catch (e) {
      rej(e);
    }
  });
};

import { delay } from "../../../../common/Utils";
export class PlugWsSignalsHandler {
  constructor(socket, store, actions, janusActions, localStorageAction) {
    this.socket = socket;
    this.store = store;
    this.actions = actions;
    this.localStorageAction = localStorageAction;
    this.janusActions = janusActions;
    this.signalSubscribed = false;
  }

  sortGroups(groups) {
    let latestLineOnTime = this.store.getState().latestLineOnTime;
    let uid = this.store.getState().user.uid
    let lastMsgInGroup = this.localStorageAction.getLastMsgInGroup(uid);
    let resultingTimeForSorting = {};
    groups.map((grp) => {
      if (!grp.groupid) return;
      if (
        (latestLineOnTime[grp.groupid]?.lineOnTime ?? 0) >
        (lastMsgInGroup[grp.groupid] || 0)
      ) {
        resultingTimeForSorting[grp.groupid] =
          latestLineOnTime[grp.groupid]?.lineOnTime;
      } else {
        if (
          (latestLineOnTime[grp.groupid]?.lineOnTime ?? 0) == 0 &&
          (lastMsgInGroup[grp.groupid] || 0) == 0
        ) {
          resultingTimeForSorting[grp.groupid] =
            latestLineOnTime[grp.groupid]?.created_at;
        } else {
          resultingTimeForSorting[grp.groupid] = lastMsgInGroup[grp.groupid];
        }
      }
    });

    let lineOnStatus = this.store.getState().lineOnStatus;
    groups = groups.sort((a, b) => {
      if (a.istemp && !b.istemp) return -1;
      if (!a.istemp && b.istemp) return 1;
      if (!a.seniors && b.seniors) return 1;
      if (a.seniors && !b.seniors) return -1;
      let a_lineOn = Object.keys(lineOnStatus?.[a.groupid] ?? {}).length;
      let b_lineOn = Object.keys(lineOnStatus?.[b.groupid] ?? {}).length;
      if (a_lineOn && !b_lineOn) return -1;
      if (!a_lineOn && b_lineOn) return 1;
      return (
        (resultingTimeForSorting?.[b.groupid] ?? 0) -
        (resultingTimeForSorting?.[a.groupid] ?? 0)
      );
    });

    groups = groups.map((g) => {
      let myUid = this.store.getState().user.uid;
      g.members = g.members.sort((a, b) => {
        if (a.uid === myUid) return -1;
        if (b.uid === myUid) return 1;
        if (lineOnStatus?.[g.groupid]?.[a.uid]) return -1;
        if (lineOnStatus?.[g.groupid]?.[b.uid]) return 1;
        return 0;
      });
      return g;
    });
    return groups;
  }

  async subscribe() {
    return new Promise((res, rej) => {
      if (!this.signalSubscribed) {
        this.socket.on("signal_speaking", async ({ userid, groupid }) => {
          console.log("signal_speaking", userid, groupid);
        });
        this.socket.on("signal_busy", async (response) => {
          console.log("signal_busy", response);
          let { uid, activity } = response;
          if (activity === "busyon") {
            this.store.dispatch(this.actions.setBusyStatus({ [uid]: true }));
          } else {
            this.store.dispatch(this.actions.setBusyStatus({ [uid]: false }));
          }
        });
        this.socket.on("signal_lineon", async (response) => {
          console.log("signal_line", response.activity, response);
          let { uid, groupid, activity, timestamp } = response;
          // if (groupid.includes("conv")) {
          let lineOnStatus = this.store.getState().lineOnStatus;
          let latestLineOnTime = this.store.getState().latestLineOnTime;
          let groups = this.store.getState().groups.filter((obj) => true);
          let userLineOnGroup = this.store.getState().userLineOnInGroup;
          let myUid = this.store.getState().user.uid;

          if (activity === "lineon") {

            if(uid==myUid && Object.keys(lineOnStatus?.[groupid] ?? {})?.length==0){
               // no one is in the call before and u have started the call;
               this.store.dispatch(
                this.actions.setMissedLineOnGroup({groupid:groupid,isMissed:true})
              );
            }
            
            

            latestLineOnTime = {
              ...latestLineOnTime,
              [groupid]: {
                ...latestLineOnTime[groupid],
                lineOnTime: timestamp,
              },
            };
            let localLastestLineOn = {};
            Object.keys(latestLineOnTime).forEach((groupid) => {
              localLastestLineOn[groupid] =
                latestLineOnTime[groupid].lineOnTime;
            });

            localStorage.setItem(
              "plugApp_lineon_time_conv" + myUid,
              JSON.stringify(localLastestLineOn)
            );

            this.store.dispatch(
              this.actions.setNotification({ [groupid]: uid })
            );
            // after 1 sec of setting make it null;
            setTimeout(() => {
              this.store.dispatch(this.actions.setNotification({}));
            }, 1000);
            this.store.dispatch(
              this.actions.setLatestLineonTime(latestLineOnTime)
            );

            lineOnStatus = {
              ...lineOnStatus,
              [groupid]: { ...lineOnStatus[groupid], [uid]: true },
            };
           
            if(Object.keys(lineOnStatus[groupid]).length>=2){
             
              this.store.dispatch(
                this.actions.setMissedLineOnGroup({groupid:groupid,isMissed:false})
              );
            }
            
            this.store.dispatch(this.actions.lineOnStatus(lineOnStatus));
            groups = this.sortGroups(groups);
            this.store.dispatch(this.actions.addGroupAction(groups));
          } else {
            if (this.store.getState().pendingLineOff === groupid) {
              this.store.dispatch(this.actions.setPendingLineOff(null));
            }
            let temp_Obj = { ...lineOnStatus[groupid] };
            delete temp_Obj[uid];
            lineOnStatus = { ...lineOnStatus, [groupid]: temp_Obj };
            this.store.dispatch(this.actions.lineOnStatus(lineOnStatus));
            groups = this.sortGroups(groups);
            this.store.dispatch(this.actions.addGroupAction(groups));
          }
         
          // }
        });
        
      }

      this.socket.emit(
        "subscribe",
        {
          handlerType: "signals",
        },
        async (error, response) => {
          console.log(error, response);
          // if (error) {
          //   rej();
          //   return;
          // }
          // console.log(this.store, this.actions, response.signal_lineon);
          let latestLineOnTime = this.store.getState().latestLineOnTime;

          let myUid = this.store.getState().user.uid;
          let lineOnInGroup = this.store.getState().userLineOnInGroup;
          this.signalSubscribed = true;
          let lineOnStatus = {};
          let signalBusy = this.store.getState().busyStatus;

          Object.keys(signalBusy).forEach((uid) => {
            signalBusy[uid] = false;
          });

          Object.keys(response.signal_lineon).forEach((groupid) => {
            // if (groupid.includes("conv")) {
            lineOnStatus[groupid] = {};

            response.signal_lineon[groupid].forEach((uid) => {
              lineOnStatus[groupid][uid] = true;
            });
            // }
          });

          Object.keys(lineOnStatus).forEach(async (groupid) => {
            if (response.signal_lineon[groupid].length) {
              console.log(
                "create the janus connection for user in group",
                groupid
              );
              latestLineOnTime = {
                ...latestLineOnTime,
                [groupid]: {
                  ...latestLineOnTime[groupid],
                  lineOnTime: new Date().getTime(),
                },
              };

              await this.store.dispatch(
                this.janusActions.createJanusHandle(
                  groupid,
                  groupid === lineOnInGroup ? true : false,
                  null
                )
              );
              await delay(1000);
            }
          });

          if (lineOnInGroup && !lineOnStatus[lineOnInGroup]?.length) {
            console.log(
              this.store.dispatch(
                this.janusActions.createJanusHandle(lineOnInGroup, true, null)
              )
            );
          }

          response.signal_busy.forEach((uid) => {
            signalBusy[uid] = true;
          });
          // console.log(latestLineOnTime, groups);

          this.store.dispatch(
            this.actions.setLatestLineonTime(latestLineOnTime)
          );
          this.store.dispatch(this.actions.setBusyStatus(signalBusy));
          let localLastestLineOn = {};
          Object.keys(latestLineOnTime).forEach((groupid) => {
            localLastestLineOn[groupid] = latestLineOnTime[groupid].lineOnTime;
          });
          // console.log(
          //   groups,
          //   latestLineOnTime,
          //   signalBusy,
          //   lineOnStatus,
          //   localLastestLineOn
          // );

          // let lastMsgInGroup = this.localStorageAction.getLastMsgInGroup();
          // console.log(latestLineOnTime, lastMsgInGroup);

          localStorage.setItem(
            "plugApp_lineon_time_conv" + myUid,
            JSON.stringify(localLastestLineOn)
          );
          this.store.dispatch(this.actions.lineOnStatus(lineOnStatus));

          let groups = this.store.getState().groups.filter((res) => true);

          groups = this.sortGroups(groups);
          this.store.dispatch(this.actions.addGroupAction(groups));
          this.store.dispatch(this.actions.signalSubscribed(true))
          console.log("signals has been connnected");
          
          res();
        }
      );
    });
  }

  async handleIncorrentLineOn(requestType, payload, response) {
    if (requestType === "setLineOn" && response.activity === "lineoff") {
      await this.makeRequest("setLineOff", payload);
    } else if (requestType === "setLineOff" && response.activity === "lineon") {
      await this.makeRequest("setLineOn", payload);
    }
  }

  async debounce(fn, params, time, attempt) {
    const MAX_ATTEMPTS = 20;
    if (attempt >= MAX_ATTEMPTS) return;
    await delay(time);
    return fn.bind(this)(...params, attempt);
  }

  makeRequest(requestType, payload, attempt = 0) {
    if (!this.signalSubscribed) {
      return this.debounce(
        this.makeRequest,
        [requestType, payload],
        500,
        attempt + 1
      );
    }
    return new Promise((res, rej) => {
      this.socket.emit(
        "makeRequest",
        {
          handlerType: "signals",
          data: {
            request: requestType,
            payload: payload,
          },
        },
        (error, response) => {
          console.log(`signal ${requestType}`, error, response);

          if (error) {
            rej();
          } else {
            res(this.handleIncorrentLineOn(requestType, payload, response));
          }
        }
      );
    });
  }
}

import { setUserNewAccessToken } from "../../Redux/Actions/Action";
import { isElectron } from "../common/Utils";

export default class FirebaseClient {
  constructor() {
    this.firebase = null;
    this.database = null;
    this.analytics = null;
    this.user = null;
    this.storage = null;
  }
  init(firebase) {
    this.firebase = firebase;
    this.database = firebase.database();
    this.analytics = firebase.analytics();
    this.storage = firebase.storage();
    // console.log(this.firebase.auth.currentUser);
  }
  setUser(user) {
    this.user = user;
    // console.log(this.user.auth.currentUser.email);
  }
  async getToken() {
    if (this.user) {
      let token = await this.user.getIdToken();
      return token;
    } else {
      return null;
    }
  }
  fetchMessage(groupid, length) {
    return new Promise((res, rej) => {
      let result = null;

      this.database
        .ref(`company/common/messages/${groupid}`)
        .orderByChild("createdat")
        .limitToLast(100 + length)
        .once("value")
        .then((snap) => {
          // console.log(snap.val());
          result = snap.val();
          res(result);
        })
        .catch((e) => {
          rej(e);
        });
    });
  }
  trackEvent(eventName, params = {}) {
    this.analytics.logEvent(
      eventName,
      Object.assign({}, params, {
        useremail: (this.user?.auth?.currentUser ?? {}).email,
        fbuid: (this.user?.auth?.currentUser ?? {}).uid,
        userdomain: ((this.user?.auth?.currentUser ?? {}).email || "").split(
          "@"
        )[1],
        platform: isElectron() ? "electron" : "browser",
        version: "v1",
      })
    );
  }

  async getUrl(path) {
    return new Promise(async (res, rej) => {
      try {
        const storageRef = this.storage.ref();

        const fileRef = storageRef.child(path);

        const downloadurl = await fileRef.getDownloadURL();

        res(downloadurl);
      } catch (e) {
        console.log(e);
        rej(e);
      }
    });
  }

  async uploadFile(file, fileType) {
    return new Promise(async (res, rej) => {
      try {
        const storageRef = this.storage.ref();
        const fileId =
          new Date().getTime() + "_" + Math.floor(2000 * Math.random());
        console.log(fileId);
        const fileRef = storageRef.child(`${fileType}/${fileId}`);

        const result = await fileRef.put(file);

        // const downloadurl = await fileRef.getDownloadURL();

        res(result._delegate.ref._location.path_);
      } catch (e) {
        console.log(e);
        rej(e);
      }
    });
  }
}

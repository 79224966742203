function checkGlobalGroupFilter(g) {
  return g.conversation || g.istemp;
}

export class PlugWsGroupsHandler {
  constructor(
    socket,
    store,
    actions,
    wsActions,
    janusActions,
    localStorageAction
  ) {
    this.socket = socket;
    this.store = store;
    this.actions = actions;
    this.wsActions = wsActions;
    this.janusActions = janusActions;
    this.localStorageAction = localStorageAction;
    this.groupSubscribed = false;
  }

  sortGroups(groups) {
    let latestLineOnTime = this.store.getState().latestLineOnTime;
    let uid = this.store.getState().user.uid
    let lastMsgInGroup = this.localStorageAction.getLastMsgInGroup(uid);
    let resultingTimeForSorting = {};
    groups.map((grp) => {
      if (!grp.groupid) return;
      if (
        (latestLineOnTime[grp.groupid]?.lineOnTime ?? 0) >
        (lastMsgInGroup[grp.groupid] || 0)
      ) {
        resultingTimeForSorting[grp.groupid] =
          latestLineOnTime[grp.groupid]?.lineOnTime;
      } else {
        if (
          (latestLineOnTime[grp.groupid]?.lineOnTime ?? 0) == 0 &&
          (lastMsgInGroup[grp.groupid] || 0) == 0
        ) {
          resultingTimeForSorting[grp.groupid] =
            latestLineOnTime[grp.groupid]?.created_at;
        } else {
          resultingTimeForSorting[grp.groupid] = lastMsgInGroup[grp.groupid];
        }
      }
    });

    let lineOnStatus = this.store.getState().lineOnStatus;
    groups = groups.sort((a, b) => {
      if (a.istemp && !b.istemp) return -1;
      if (!a.istemp && b.istemp) return 1;
      if (!a.seniors && b.seniors) return 1;
      if (a.seniors && !b.seniors) return -1;
      let a_lineOn = Object.keys(lineOnStatus?.[a.groupid] ?? {}).length;
      let b_lineOn = Object.keys(lineOnStatus?.[b.groupid] ?? {}).length;
      if (a_lineOn && !b_lineOn) return -1;
      if (!a_lineOn && b_lineOn) return 1;
      return (
        (resultingTimeForSorting?.[b.groupid] ?? 0) -
        (resultingTimeForSorting?.[a.groupid] ?? 0)
      );
    });

    groups = groups.map((g) => {
      const myUid = this.store.getState().user.uid;
      g.members = g.members.sort((a, b) => {
        if (a.uid == myUid) return -1;
        if (b.uid == myUid) return 1;
        if (lineOnStatus?.[g.groupid]?.[a.uid]) return -1;
        if (lineOnStatus?.[g.groupid]?.[b.uid]) return 1;
        return 0;
      });
      return g;
    });
    return groups;
  }

  subscribe() {
    return new Promise((res, rej) => {
      if (!this.groupSubscribed) {
        this.socket.on("group_details", ({ type, uid, group }) => {
          console.log("group_details", type, uid, group);
          if (type == "createGroup" && checkGlobalGroupFilter(group)) {
            const myUid = this.store.getState().user.uid;
            const anotherPersonUid = group.members.filter(
              (u) => u.uid != myUid
            )[0]?.uid;

            if (group.created_by == myUid) {
              if (!group.istemp) window.location.reload(true);
            }

            let groups = this.store.getState().groups;

            groups = groups.filter((g) => g.virtualuid != anotherPersonUid);

            const firstSeen =
              groups.filter((g) => g.groupid == group.groupid).length == 0;
            groups = groups
              .filter((g) => g.groupid != group.groupid)
              .concat([group]);
            groups = this.sortGroups(groups);
            this.store.dispatch(this.actions.addGroupAction(groups));

            if (firstSeen) {
              this.store.dispatch(this.wsActions.customGroupSubscribe(group));
              let lineOnInGroup = this.store.getState().showLineOnPopUp;
              if (lineOnInGroup && lineOnInGroup == group.parentGroupid) {
                this.store.dispatch(this.actions.newGroup(null));
                this.store.dispatch(this.actions.autoPlayRecording(false));
                this.store.dispatch(this.actions.showRecorder(false));
                this.store.dispatch(
                  this.wsActions.setLineOff({ groupid: group.parentGroupid })
                );
                this.store.dispatch(
                  this.janusActions.lineOffAction(group.parentGroupid)
                );
                this.store.dispatch(this.actions.showLineOnPopUp(false));
                this.store.dispatch(
                  this.actions.activateGroup({
                    groupName: "",
                    members: [],
                    groupid: "",
                  })
                );
                this.store.dispatch(
                  this.actions.showSeniorsConfirmationPopUp(false)
                );

                setTimeout(() => {
                  this.store.dispatch(this.actions.activateGroup(group));
                  if (group.created_by == myUid) {
                    this.store.dispatch(this.actions.showGroupSettings(true));
                  }
                  setTimeout(() => {
                    // this.store.dispatch(this.wsActions.setLineOn({ groupid: group.groupid }));
                    // this.store.dispatch(this.janusActions.createJanusHandle(group.groupid, true, null))
                    // this.store.dispatch(this.actions.showLineOnPopUp(group.groupid));
                    // this.store.dispatch(this.actions.setUserLineOnInGroup(group.groupid));
                    // this.store.dispatch(this.actions.setNewMsgInGroup({ [group.groupid]: false }));
                    // this.store.dispatch(this.actions.showChatBoxAction(group.groupid));
                  }, 2000);
                }, 600);
              }
            }
            if (!group.istemp) {
              this.store.dispatch(this.actions.newGroup("created"));
              setTimeout(() => {
                this.store.dispatch(this.actions.newGroup(null));
              }, 600);
            }
          } else if (type == "updateGroup" && checkGlobalGroupFilter(group)) {
            let groups = this.store.getState().groups;
            const firstSeen =
              groups.filter((g) => g.groupid == group.groupid).length == 0;
            groups = groups
              .filter((g) => g.groupid != group.groupid)
              .concat([group]);
            groups = this.sortGroups(groups);
            this.store.dispatch(this.actions.addGroupAction(groups));
            if (firstSeen) {
              this.store.dispatch(this.wsActions.customGroupSubscribe(group));
            }
          } else if (type == "deleteGroup") {
            let groups = this.store.getState().groups;
            let lineOnInGroup = this.store.getState().showLineOnPopUp;
            if (group.groupid == lineOnInGroup) {
              this.store.dispatch(this.actions.newGroup("deleted"));
              setTimeout(() => {
                this.store.dispatch(this.actions.newGroup(null));
                this.store.dispatch(this.actions.autoPlayRecording(false));
                this.store.dispatch(this.actions.showRecorder(false));
                this.store.dispatch(
                  this.wsActions.setLineOff({ groupid: group.groupid })
                );
                this.store.dispatch(
                  this.janusActions.lineOffAction(group.groupid)
                );
                this.store.dispatch(this.actions.showLineOnPopUp(false));
                this.store.dispatch(
                  this.actions.activateGroup({
                    groupName: "",
                    members: [],
                    groupid: "",
                  })
                );
                this.store.dispatch(
                  this.actions.showSeniorsConfirmationPopUp(false)
                );
                this.store.dispatch(this.actions.showGroupSettings(false));
              }, 600);
            }
            groups = groups.filter((g) => g.groupid != group.groupid);
            groups = this.sortGroups(groups);
            this.store.dispatch(this.actions.addGroupAction(groups));
            if (groups.length == 0) {
              this.store.dispatch(this.actions.groupExist(false));
            }
          }
        });
        this.socket.on("group_message", ({ groupid, payload, uid }) => {
          console.log("group_message", groupid, payload, uid);

          let message = {};
          let chatBoxOnInGroup = this.store.getState().showChatBox;
          let groups = this.store.getState().groups.filter((obj) => true);
          const useruid = this.store.getState().user.uid;
          Object.keys(payload).forEach((key) => {
            message = payload[key];
          });
          if (this.store.getState().groupsChats?.[groupid]) {
            this.store.dispatch(
              this.actions.newGroupChat({
                groupid: [groupid],
                message: [message],
                oldMessage: [],
              })
            );
          }
          // console.log(this.store.getState().groupsChats);

          if (message.mediaurl && message.senderuid !== useruid) {
            this.store.dispatch(
              this.actions.setLastRecordingTimestamp({
                groupid: [groupid],
                timestamp: message.createdat,
              })
            );
          }
          
          this.localStorageAction.setLastMsgInGroup(groupid, message.createdat,uid);

          groups = this.sortGroups(groups);
          this.store.dispatch(this.actions.addGroupAction(groups));
          if (useruid !== message.senderuid) {
            this.store.dispatch(this.actions.setNewMsg(groupid));
            if (message.type == "NUDGE") {
              this.store.dispatch(
                this.actions.setMsgContent(
                  `${message.sendername} has requested for a call back.`
                )
              );
              this.store.dispatch(
                this.actions.setNewMsgInGroup({ [groupid]: "NUDGE" })
              );
            } else if (message.mediaurl) {
              this.store.dispatch(
                this.actions.setMsgContent(
                  `Voice Note received from ${message.sendername}`
                )
              );
              this.store.dispatch(
                this.actions.setNewMsgInGroup({ [groupid]: "MEDIA" })
              );
            } else {
              this.store.dispatch(
                this.actions.setMsgContent(
                  `Message received from ${message.sendername}`
                )
              );
              this.store.dispatch(
                this.actions.setNewMsgInGroup({ [groupid]: "MSG" })
              );
            }
            setTimeout(() => {
              this.store.dispatch(this.actions.setNewMsg(false));
              this.store.dispatch(this.actions.setMsgContent(null));
            }, 1000);
          }
        });
       
        
      }
      // subscribing to the group to get the users group details.
      this.socket.emit(
        "subscribe",
        {
          handlerType: "groups",
        },
        async (error, response) => {
          // console.log(error, response);
          this.groupSubscribed = true;
          let userToGroupMap = {};
          let onlineInGroup = {};
          let filteredGroups = {};
          let lineOnTime = {};
          let myUid = this.store.getState().user.uid;
          let groupLocalStorageLineOnTime = localStorage.getItem(
            "plugApp_lineon_time_conv" + myUid
          );

          if (groupLocalStorageLineOnTime == null) {
            groupLocalStorageLineOnTime = {};
            response.forEach((ele) => {
              groupLocalStorageLineOnTime[ele.groupid] = 0;
            });

            localStorage.setItem(
              "plugApp_lineon_time_conv" + myUid,
              JSON.stringify(groupLocalStorageLineOnTime)
            );
          } else {
            groupLocalStorageLineOnTime = JSON.parse(
              groupLocalStorageLineOnTime
            );
            response.forEach((ele) => {
              if (!groupLocalStorageLineOnTime[ele.groupid])
                groupLocalStorageLineOnTime[ele.groupid] = 0;
            });
          }

          if (response.length == 0) {
            this.store.dispatch(this.actions.groupExist(false));
          } else {
            response.forEach((element) => {
              onlineInGroup[element.groupid] = [];
              lineOnTime[element.groupid] = {
                lineOnTime: groupLocalStorageLineOnTime[element.groupid],
                created_at: element.created_at
                  ? element.created_at
                  : element.updated_at,
              };

              element.members.forEach((user) => {
                if (userToGroupMap[`${user.uid}`] == undefined) {
                  userToGroupMap[`${user.uid}`] = {};
                }
                userToGroupMap[`${user.uid}`] = {
                  ...userToGroupMap[`${user.uid}`],
                  [element.groupid]: true,
                };
              });
            });
            filteredGroups = response.filter((obj) =>
              checkGlobalGroupFilter(obj)
            );

            this.store.dispatch(this.actions.setUserToGroupMap(userToGroupMap));
            this.store.dispatch(this.actions.setOnlineInGroup(onlineInGroup));
            // this.store.dispatch(this.actions.newGroupChat(onlineInGroup));

            this.store.dispatch(this.actions.setLatestLineonTime(lineOnTime));
            filteredGroups = this.sortGroups(filteredGroups);
            this.store.dispatch(this.actions.addGroupAction(filteredGroups));
          }
          // console.log(
          //   response,
          //   onlineInGroup,
          //   userToGroupMap,
          //   lineOnTime,
          //   filteredGroups
          // );
          this.store.dispatch(this.actions.groupSubscribed(true))
          res();
        }
      );
    });
  }
  makeRequest(requestType, payload) {
    console.log("makeRequest", requestType, payload);
    return new Promise((res, rej) => {
      this.socket.emit(
        "makeRequest",
        {
          handlerType: "groups",
          data: {
            request: requestType,
            payload: payload,
          },
        },
        (error, response) => {
          console.log(requestType, error, response);

          if (error) {
            rej();
          } else {
            this.store.dispatch(this.actions.showCreateGroup(false));
            res();
          }
          // handleMakeRequestResponse(
          //   response,
          //   requestType,
          //   this.store,
          //   this.actions
          // );
        }
      );
    });
  }
}

import "./assets/css/App.css";
import React, { useState } from "react";
import Login from "./UIComponents/Screens/Login/Login";
import Home from "./UIComponents/Screens/Home/Home";
// import "antd/dist/antd.less";
import "antd/dist/antd.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import { connect } from "react-redux";
function App(props) {
  const bgColor = props.bgColor;
  return (
    <React.Fragment>
      <div
        className="bg-page"
        style={{
          backgroundColor: bgColor,
        }}
      ></div>
      <div className="main-page">
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="home"
              element={
                props.logged ? (
                  <Home store={props.store} />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          </Routes>
        </Router>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(App);
